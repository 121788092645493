import axios from "axios";
import qs from "qs";
import { PARAMS_SERIALIZER_DEFAULT_OPTIONS } from "@/utils/Constants";
import { cleanObject } from "@/utils/History";

/**
 * Api
 * Instância com interceptors:
 * 401 - Abre modal de login
 * 403 - Abre modal de cadastro (caso esteja deslogado)
 * 419 - Atualiza token do usuário
 *
 * Qualquer outro erro mostra uma notificação para o usuário
 */

const viaCepApi = axios.create({
  baseURL: `https://viacep.com.br/ws/`,
  paramsSerializer: (params) => {
    cleanObject(params);
    return qs.stringify(params, PARAMS_SERIALIZER_DEFAULT_OPTIONS);
  },
});
export default viaCepApi;
