<template>
  <a-row>
    <a-col span="24">
      <a-row>
        <a-col span="24" :class="$style.space">
          <OccupationSelect
            reference="workPresentOccupation"
            name="Ocupação primária"
            :isRequired="vForm.workPresentOccupation != 'N'"
          />
        </a-col>
        <a-col span="24">
          <div
            v-if="
              vForm.workPresentOccupation == 'O' ||
              vForm.workPresentOccupation == 'N'
            "
          >
            <a-col span="24" :class="[$style.space, $style.blueSpace]">
              <a-form-item
                ref="workPresentOccupationExplain"
                label="Especifique outro"
                name="workPresentOccupationExplain"
                :rules="{
                  required:
                    vForm.workPresentOccupation == 'O' ||
                    vForm.workPresentOccupation == 'N',
                  message: 'Campo obrigatório',
                  trigger: 'blur',
                }"
              >
                <a-textarea
                  v-model:value="vForm.workPresentOccupationExplain"
                  placeholder="Explique"
                  :readonly="readOnlyForm"
                  :rows="4"
                />
              </a-form-item>
            </a-col>
          </div>
        </a-col>
        <a-col span="24">
          <div v-if="vForm.workPresentOccupation != 'N'">
            <a-col span="24" :class="[$style.space, $style.blueSpace]">
              <a-form-item
                ref="workPresentEmployerSchoolName"
                label="Empregador atual ou nome da escola"
                :rules="{
                  required: vForm.workPresentOccupation != 'N',
                  message: 'Campo obrigatório',
                  trigger: 'blur',
                }"
                name="workPresentEmployerSchoolName"
              >
                <a-input
                  v-model:value="vForm.workPresentEmployerSchoolName"
                  :readonly="readOnlyForm"
                >
                </a-input>
              </a-form-item>
              <a-col span="24" :class="$style.whiteSpace">
                <a-col span="24" :class="$style.space">
                  <CountrySelect
                    reference="workPresentCountry"
                    name="País"
                    :isRequired="vForm.workPresentOccupation != 'N'"
                  />
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-form-item
                    ref="workPresentZipCode"
                    label="CEP"
                    name="workPresentZipCode"
                    :required="isBrazilian"
                  >
                    <a-input
                      @change="getUserAddress()"
                      v-maska="isBrazilian ? '#####-###' : '#*'"
                      v-model:value="vForm.workPresentZipCode"
                      :loading="isLoadingViaCep"
                      :disabled="isLoadingViaCep"
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-row>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="workPresentStreetLine1"
                      label="Endereço"
                      name="workPresentStreetLine1"
                      :required="vForm.workPresentOccupation != 'N'"
                    >
                      <a-input
                        v-model:value="vForm.workPresentStreetLine1"
                        :disabled="isLoadingViaCep"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="workPresentStreetLine2"
                      label="Complemento"
                      name="workPresentStreetLine2"
                    >
                      <a-input
                        v-model:value="vForm.workPresentStreetLine2"
                        :disabled="isLoadingViaCep"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="workPresentCity"
                      label="Cidade"
                      name="workPresentCity"
                      :required="vForm.workPresentOccupation != 'N'"
                    >
                      <a-input
                        v-model:value="vForm.workPresentCity"
                        :disabled="isLoadingViaCep"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="workPresentState"
                      label="Estado"
                      :required="vForm.workPresentOccupation != 'N'"
                      name="workPresentState"
                    >
                      <a-input
                        v-model:value="vForm.workPresentState"
                        :disabled="isLoadingViaCep"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="24" :class="$style.space">
                    <a-form-item
                      ref="workPresentPhoneNumber"
                      label="Número de telefone"
                      :required="vForm.workPresentOccupation != 'N'"
                      name="workPresentPhoneNumber"
                    >
                      <a-input
                        v-maska="isBrazilian ? '+55 (##) #####-####' : '#*'"
                        v-model:value="vForm.workPresentPhoneNumber"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="workPresentStartDate"
                      label="Data de Inicio"
                      name="workPresentStartDate"
                      :rules="{
                        required: vForm.workPresentOccupation != 'N',
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                        validator: validateDate,
                      }"
                    >
                      <a-date-picker
                        v-model:value="vForm.workPresentStartDate"
                        type="date"
                        placeholder="Selecione"
                        style="width: 100%"
                        @change="handleWorkStartDate"
                        :disabled-date="overCurrentDate"
                        :readonly="readOnlyForm"
                        :format="DEFAULT_DATE_FORMAT"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="workPresentMonthlyIncome"
                      label="Renda mensal (se houver)"
                      name="workPresentMonthlyIncome"
                    >
                      <a-input
                        v-maska="'#*'"
                        v-model:value="vForm.workPresentMonthlyIncome"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="24" :class="[$style.space, $style.blueSpace]">
                    <a-form-item
                      ref="workPresentBrieflyDescribe"
                      label="Descreva brevemente suas funções:"
                      name="workPresentBrieflyDescribe"
                      :required="vForm.workPresentOccupation != 'N'"
                    >
                      <a-textarea
                        v-model:value="vForm.workPresentBrieflyDescribe"
                        placeholder="Explique"
                        :readonly="readOnlyForm"
                        :rows="4"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>
            </a-col>
          </div>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col span="24" :class="$style.space">
          <a-form-item
            ref="workPreviousWereYouPreviouslyEmployed"
            label="Você ja trabalhou antes?"
            name="workPreviousWereYouPreviouslyEmployed"
            :required="true"
          >
            <a-radio-group
              v-model:value="vForm.workPreviousWereYouPreviouslyEmployed"
              :readonly="readOnlyForm"
            >
              <a-radio-button value="Y">Sim</a-radio-button>
              <a-radio-button value="N">Não</a-radio-button>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      <a-col span="24">
        <div v-if="vForm.workPreviousWereYouPreviouslyEmployed == 'Y'">
          <a-col
            span="24"
            :class="[$style.space, $style.blueSpace, $style.topSpace]"
            v-for="(item, amount) in this.vForm.workPreviousEmployerName"
            :key="amount"
          >
            <a-col span="24">
              <a-form-item
                ref="workPreviousEmployerName"
                label="Nome do Empregador"
                :rules="{
                  required: wereEmployedBefore,
                  message: 'Campo obrigatório',
                  trigger: 'blur',
                }"
                :name="['workPreviousEmployerName', amount, 'value']"
              >
                <a-input
                  v-model:value="vForm.workPreviousEmployerName[amount].value"
                  :readonly="readOnlyForm"
                >
                </a-input>
              </a-form-item>
              <a-col span="24" :class="$style.whiteSpace">
                <a-col span="24" :class="$style.space">
                  <multi-country-select
                    reference="workPreviousEmployerCountry"
                    name="País"
                    :identification="amount"
                    :isRequired="wereEmployedBefore"
                  />
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-form-item
                    ref="workPreviousEmployerZipCode"
                    label="CEP"
                    :name="['workPreviousEmployerZipCode', amount, 'value']"
                    :rules="{
                      required: isBrazilianPrevious(amount),
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                    }"
                  >
                    <a-input
                      @change="getPreviousAddress(amount)"
                      v-maska="isBrazilianPrevious(amount) ? '#####-###' : '#*'"
                      v-model:value="
                        vForm.workPreviousEmployerZipCode[amount].value
                      "
                      :loading="isLoadingViaCep"
                      :disabled="isLoadingViaCep"
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-row>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="workPreviousEmployerStreetLine1"
                      label="Endereço"
                      :name="[
                        'workPreviousEmployerStreetLine1',
                        amount,
                        'value',
                      ]"
                      :rules="{
                        required: wereEmployedBefore,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                      }"
                    >
                      <a-input
                        v-model:value="
                          vForm.workPreviousEmployerStreetLine1[amount].value
                        "
                        :disabled="isLoadingViaCep"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="workPreviousEmployerStreetLine2"
                      label="Complemento"
                      :name="[
                        'workPreviousEmployerStreetLine2',
                        amount,
                        'value',
                      ]"
                    >
                      <a-input
                        v-model:value="
                          vForm.workPreviousEmployerStreetLine2[amount].value
                        "
                        :disabled="isLoadingViaCep"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="workPreviousEmployerCity"
                      label="Cidade"
                      :name="['workPreviousEmployerCity', amount, 'value']"
                      :rules="{
                        required: wereEmployedBefore,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                      }"
                    >
                      <a-input
                        v-model:value="
                          vForm.workPreviousEmployerCity[amount].value
                        "
                        :disabled="isLoadingViaCep"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="workPreviousEmployerState"
                      label="Estado"
                      :rules="{
                        required: wereEmployedBefore,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                      }"
                      :name="['workPreviousEmployerState', amount, 'value']"
                    >
                      <a-input
                        v-model:value="
                          vForm.workPreviousEmployerState[amount].value
                        "
                        :disabled="isLoadingViaCep"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="24" :class="$style.space">
                    <a-form-item
                      ref="workPreviousEmployerTelephoneNumber"
                      label="Número de telefone"
                      :rules="{
                        required: wereEmployedBefore,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                      }"
                      :name="[
                        'workPreviousEmployerTelephoneNumber',
                        amount,
                        'value',
                      ]"
                    >
                      <a-input
                        v-maska="
                          isBrazilianPrevious(amount)
                            ? '+55 (##) #####-####'
                            : '#*'
                        "
                        v-model:value="
                          vForm.workPreviousEmployerTelephoneNumber[amount]
                            .value
                        "
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="24" :class="$style.space">
                    <a-form-item
                      ref="workPreviousEmployerJobTitle"
                      label="Função"
                      :rules="{
                        required: wereEmployedBefore,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                      }"
                      :name="['workPreviousEmployerJobTitle', amount, 'value']"
                    >
                      <a-input
                        v-model:value="
                          vForm.workPreviousEmployerJobTitle[amount].value
                        "
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="workPreviousEmployerSupervisorGN"
                      label="Nome do Supervisor"
                      :name="[
                        'workPreviousEmployerSupervisorGN',
                        amount,
                        'value',
                      ]"
                    >
                      <a-input
                        v-model:value="
                          vForm.workPreviousEmployerSupervisorGN[amount].value
                        "
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="workPreviousEmployerSupervisorSN"
                      label="Sobrenome do Supervisor"
                      :name="[
                        'workPreviousEmployerSupervisorSN',
                        amount,
                        'value',
                      ]"
                    >
                      <a-input
                        v-model:value="
                          vForm.workPreviousEmployerSupervisorSN[amount].value
                        "
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="workPreviousEmployerStartDate"
                      label="Data de Inicio"
                      :rules="{
                        required: wereEmployedBefore,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                        validator: validateDate,
                      }"
                      :name="['workPreviousEmployerStartDate']"
                    >
                      <a-date-picker
                        v-model:value="
                          vForm.workPreviousEmployerStartDate[amount].value
                        "
                        type="date"
                        placeholder="Selecione"
                        style="width: 100%"
                        @change="
                          (val) => handlePreviousWorkStartDate(val, amount)
                        "
                        :disabled-date="overCurrentDate"
                        :readonly="readOnlyForm"
                        :format="DEFAULT_DATE_FORMAT"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="workPreviousEmployerEndDate"
                      label="Data de encerramento"
                      :rules="{
                        required: wereEmployedBefore,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                        validator: validateDate,
                      }"
                      :name="['workPreviousEmployerEndDate']"
                    >
                      <a-date-picker
                        v-model:value="
                          vForm.workPreviousEmployerEndDate[amount].value
                        "
                        type="date"
                        placeholder="Selecione"
                        style="width: 100%"
                        @change="
                          (val) => handlePreviousWorkEndDate(val, amount)
                        "
                        :disabled-date="overCurrentDate"
                        :readonly="readOnlyForm"
                        :format="DEFAULT_DATE_FORMAT"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col span="24" :class="[$style.space, $style.blueSpace]">
                    <a-form-item
                      ref="workPreviousEmployerBrieflyDescribe"
                      label="Descreva brevemente suas funções:"
                      :name="[
                        'workPreviousEmployerBrieflyDescribe',
                        amount,
                        'value',
                      ]"
                      :rules="{
                        required: wereEmployedBefore,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                      }"
                    >
                      <a-textarea
                        v-model:value="
                          vForm.workPreviousEmployerBrieflyDescribe[amount]
                            .value
                        "
                        placeholder="Explique"
                        :readonly="readOnlyForm"
                        :rows="4"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>
            </a-col>
          </a-col>
          <a-col span="24" :class="$style.space">
            <a-button
              type="primary"
              :class="$style.outSpace"
              size="small"
              :disabled="canNotAddMore"
              @click="addPrevious()"
            >
              <template #icon>
                <PlusOutlined />
              </template>
              Adicionar mais
            </a-button>
            <a-button
              type="danger"
              size="small"
              @click="removePrevious()"
              :disabled="canNotRemove"
              :class="$style.outSpace"
            >
              <template #icon>
                <MinusOutlined />
              </template>
              Remover
            </a-button>
          </a-col>
        </div>
      </a-col>
      <a-divider />
      <a-row>
        <a-col span="24" :class="$style.space">
          <a-form-item
            ref="workPreviousHaveAttendedAnyEducational"
            label="Você frequentou alguma instituição educacional de nível médio ou superior?"
            name="workPreviousHaveAttendedAnyEducational"
            :required="true"
          >
            <a-radio-group
              v-model:value="vForm.workPreviousHaveAttendedAnyEducational"
              :readonly="readOnlyForm"
            >
              <a-radio-button value="Y">Sim</a-radio-button>
              <a-radio-button value="N">Não</a-radio-button>
            </a-radio-group>
          </a-form-item>
        </a-col>
      </a-row>
      <a-col span="24">
        <div v-if="vForm.workPreviousHaveAttendedAnyEducational == 'Y'">
          <a-col
            span="24"
            :class="[$style.space, $style.blueSpace, $style.topSpace]"
            v-for="(item, amount) in this.vForm
              .previousEducationInstitutionName"
            :key="amount"
          >
            <a-col span="24">
              <a-form-item
                ref="previousEducationInstitutionName"
                label="Nome da instituição"
                :rules="{
                  required: wereStudiedBefore,
                  message: 'Campo obrigatório',
                  trigger: 'blur',
                }"
                :name="['previousEducationInstitutionName', amount, 'value']"
              >
                <a-input
                  v-model:value="
                    vForm.previousEducationInstitutionName[amount].value
                  "
                  :readonly="readOnlyForm"
                >
                </a-input>
              </a-form-item>
              <a-col span="24" :class="$style.whiteSpace">
                <a-col span="24" :class="$style.space">
                  <multi-country-select
                    reference="previousEducationInstitutionCountry"
                    name="País"
                    :identification="amount"
                    :isRequired="wereStudiedBefore"
                  />
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-form-item
                    ref="previousEducationInstitutionZipCode"
                    label="CEP"
                    :name="[
                      'previousEducationInstitutionZipCode',
                      amount,
                      'value',
                    ]"
                    :rules="{
                      required: isBrazilianStudied(amount),
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                    }"
                  >
                    <a-input
                      @change="getPreviousStudyAddress(amount)"
                      v-maska="isBrazilianStudied(amount) ? '#####-###' : '#*'"
                      v-model:value="
                        vForm.previousEducationInstitutionZipCode[amount].value
                      "
                      :loading="isLoadingViaCep"
                      :disabled="isLoadingViaCep"
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-row>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="previousEducationInstitutionStreetLine1"
                      label="Endereço"
                      :name="[
                        'previousEducationInstitutionStreetLine1',
                        amount,
                        'value',
                      ]"
                      :rules="{
                        required: wereStudiedBefore,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                      }"
                    >
                      <a-input
                        v-model:value="
                          vForm.previousEducationInstitutionStreetLine1[amount]
                            .value
                        "
                        :disabled="isLoadingViaCep"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="previousEducationInstitutionStreetLine2"
                      label="Complemento"
                      :name="[
                        'previousEducationInstitutionStreetLine2',
                        amount,
                        'value',
                      ]"
                    >
                      <a-input
                        v-model:value="
                          vForm.previousEducationInstitutionStreetLine2[amount]
                            .value
                        "
                        :disabled="isLoadingViaCep"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="previousEducationInstitutionCity"
                      label="Cidade"
                      :name="[
                        'previousEducationInstitutionCity',
                        amount,
                        'value',
                      ]"
                      :rules="{
                        required: wereStudiedBefore,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                      }"
                    >
                      <a-input
                        v-model:value="
                          vForm.previousEducationInstitutionCity[amount].value
                        "
                        :disabled="isLoadingViaCep"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="previousEducationInstitutionState"
                      label="Estado"
                      :rules="{
                        required: wereStudiedBefore,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                      }"
                      :name="[
                        'previousEducationInstitutionState',
                        amount,
                        'value',
                      ]"
                    >
                      <a-input
                        v-model:value="
                          vForm.previousEducationInstitutionState[amount].value
                        "
                        :disabled="isLoadingViaCep"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="24" :class="$style.space">
                    <a-form-item
                      ref="previousEducationInstitutionCourseStudy"
                      label="Curso de Estudo"
                      :rules="{
                        required: wereStudiedBefore,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                      }"
                      :name="[
                        'previousEducationInstitutionCourseStudy',
                        amount,
                        'value',
                      ]"
                    >
                      <a-input
                        v-model:value="
                          vForm.previousEducationInstitutionCourseStudy[amount]
                            .value
                        "
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="previousEducationInstitutionStartDate"
                      label="Data de Inicio"
                      :rules="{
                        required: wereStudiedBefore,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                        validator: validateDate,
                      }"
                      :name="[
                        'previousEducationInstitutionStartDate',
                        amount,
                        'value',
                      ]"
                    >
                      <a-date-picker
                        v-model:value="
                          vForm.previousEducationInstitutionStartDate[amount]
                            .value
                        "
                        type="date"
                        placeholder="Selecione"
                        style="width: 100%"
                        @change="
                          (val) => handlePreviousStudyStartDate(val, amount)
                        "
                        :disabled-date="overCurrentDate"
                        :readonly="readOnlyForm"
                        :format="DEFAULT_DATE_FORMAT"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col span="12" :class="$style.space">
                    <a-form-item
                      ref="previousEducationInstitutionEndDate"
                      label="Data de encerramento"
                      :name="[
                        'previousEducationInstitutionEndDate',
                        amount,
                        'value',
                      ]"
                      :rules="{
                        required: wereStudiedBefore,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                        validator: validateDate,
                      }"
                    >
                      <a-date-picker
                        v-model:value="
                          vForm.previousEducationInstitutionEndDate[amount]
                            .value
                        "
                        type="date"
                        placeholder="Selecione"
                        style="width: 100%"
                        @change="
                          (val) => handlePreviousStudyEndDate(val, amount)
                        "
                        :disabled-date="overCurrentDate"
                        :readonly="readOnlyForm"
                        :format="DEFAULT_DATE_FORMAT"
                      />
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>
            </a-col>
          </a-col>
          <a-col span="24" :class="$style.space">
            <a-button
              type="primary"
              :class="$style.outSpace"
              size="small"
              :disabled="canNotAddEducation"
              @click="addEducation()"
            >
              <template #icon>
                <PlusOutlined />
              </template>
              Adicionar mais
            </a-button>
            <a-button
              type="danger"
              size="small"
              @click="removeEducation()"
              :disabled="canNotRemoveEducation"
              :class="$style.outSpace"
            >
              <template #icon>
                <MinusOutlined />
              </template>
              Remover
            </a-button>
          </a-col>
        </div>
      </a-col>
    </a-col>
  </a-row>
</template>
<script>
import { defineComponent } from "vue";
import { mapMutations, mapState, mapGetters } from "vuex";
import OccupationSelect from "@/components/OccupationSelect/OccupationSelect";
import CountrySelect from "@/components/CountrySelect/CountrySelect";
import { getAddress, getUFFullName } from "@/utils/getCep";
import { DEFAULT_MAX_ADD_AMOUNT, DEFAULT_DATE_FORMAT } from "@/utils/Constants";
import { sanitizeString } from "@/utils/utility";
import MultiCountrySelect from "@/components/CountrySelect/MultiCountrySelect";
import { validateDate } from "@/utils/validation";
import moment from "moment";
export default defineComponent({
  components: { CountrySelect, OccupationSelect, MultiCountrySelect },
  data() {
    return {
      amountPerson: 1,
      isLoadingViaCep: false,
      DEFAULT_DATE_FORMAT,
      validateDate,
      MAX_PREVIOUS_WORK: 2,
    };
  },
  computed: {
    ...mapState("application", ["vForm"]),
    ...mapGetters("application", ["readOnlyForm"]),
    isBrazilian() {
      return this.vForm.workPresentCountry == "BRZL";
    },
    canNotAddMore() {
      return !(
        this.vForm.workPreviousEmployerName.length < this.MAX_PREVIOUS_WORK
      );
    },
    canNotRemove() {
      return !(this.vForm.workPreviousEmployerName.length > 1);
    },

    canNotAddEducation() {
      return !(
        this.vForm.previousEducationInstitutionName.length <
        DEFAULT_MAX_ADD_AMOUNT
      );
    },
    canNotRemoveEducation() {
      return !(this.vForm.previousEducationInstitutionName.length > 1);
    },
    wereEmployedBefore() {
      return this.vForm.workPreviousWereYouPreviouslyEmployed == "Y";
    },
    wereStudiedBefore() {
      return this.vForm.workPreviousHaveAttendedAnyEducational == "Y";
    },
  },
  mounted() {
    // this.vForm.workPresentStartDate = moment(
    //   this.vForm.workPresentStartDate
    // );
    // this.vForm.workPreviousEmployerStartDate = this.vForm.workPreviousEmployerStartDate?.map(
    //   (e) => {
    //     if (e && e.value != "") {
    //       return { value: moment(e.value) };
    //     }
    //     return { value: "" };
    //   }
    // );
    // this.vForm.workPreviousEmployerEndDate = this.vForm.workPreviousEmployerEndDate?.map(
    //   (e) => {
    //     if (e && e.value != "") {
    //       return { value: moment(e.value) };
    //     }
    //     return { value: "" };
    //   }
    // );
    // this.vForm.previousEducationInstitutionStartDate = this.vForm.previousEducationInstitutionStartDate?.map(
    //   (e) => {
    //     if (e && e.value != "") {
    //       return { value: moment(e.value) };
    //     }
    //     return { value: "" };
    //   }
    // );
    // this.vForm.previousEducationInstitutionEndDate = this.vForm.previousEducationInstitutionEndDate?.map(
    //   (e) => {
    //     if (e && e.value != "") {
    //       return { value: moment(e.value) };
    //     }
    //     return { value: "" };
    //   }
    // );
  },
  methods: {
    ...mapMutations("application", ["setSaveFilledForm"]),
    overCurrentDate(current) {
      return current && current >= moment().endOf("day");
    },
    async getPreviousAddress(amount) {
      if (!this.isBrazilianPrevious(amount)) {
        return;
      }
      const cep = sanitizeString(
        this.vForm.workPreviousEmployerZipCode[amount].value
      );
      if (cep.length == 8) {
        this.isLoadingViaCep = true;
        const address = await getAddress(cep);
        if (address) {
          this.vForm.workPreviousEmployerStreetLine1[amount].value =
            address?.logradouro;
          this.vForm.workPreviousEmployerStreetLine2[amount].value =
            address?.bairro;
          this.vForm.workPreviousEmployerCity[amount].value =
            address?.localidade;
          this.vForm.workPreviousEmployerState[amount].value = getUFFullName(
            address?.uf
          );
        }
        this.isLoadingViaCep = false;
      }
    },
    async getPreviousStudyAddress(amount) {
      if (!this.isBrazilianStudied(amount)) {
        return;
      }
      const cep = sanitizeString(
        this.vForm.previousEducationInstitutionZipCode[amount].value
      );
      if (cep.length == 8) {
        this.isLoadingViaCep = true;
        const address = await getAddress(cep);
        if (address) {
          this.vForm.previousEducationInstitutionStreetLine1[amount].value =
            address?.logradouro;
          this.vForm.previousEducationInstitutionStreetLine2[amount].value =
            address?.bairro;
          this.vForm.previousEducationInstitutionCity[amount].value =
            address?.localidade;
          this.vForm.previousEducationInstitutionState[amount].value =
            getUFFullName(address?.uf);
        }
        this.isLoadingViaCep = false;
      }
    },
    isBrazilianPrevious(amount) {
      return this.vForm.workPreviousEmployerCountry[amount].value == "BRZL";
    },
    isBrazilianStudied(amount) {
      return (
        this.vForm.previousEducationInstitutionCountry[amount].value == "BRZL"
      );
    },
    async getUserAddress() {
      if (this.vForm.workPresentCountry != "BRZL") {
        return;
      }
      const cep = sanitizeString(this.vForm.workPresentZipCode);
      if (cep.length == 8) {
        this.isLoadingViaCep = true;
        const address = await getAddress(cep);
        if (address) {
          this.vForm.workPresentStreetLine1 = address?.logradouro;
          this.vForm.workPresentStreetLine2 = address?.bairro;
          this.vForm.workPresentCity = address?.localidade;
          this.vForm.workPresentState = getUFFullName(address?.uf);
        }
        this.isLoadingViaCep = false;
      }
    },
    addPrevious() {
      if (!this.canNotAddMore) {
        this.vForm.workPreviousEmployerName.push({ value: "" });
        this.vForm.workPreviousEmployerStreetLine1.push({ value: "" });
        this.vForm.workPreviousEmployerStreetLine2.push({ value: "" });
        this.vForm.workPreviousEmployerCity.push({ value: "" });
        this.vForm.workPreviousEmployerState.push({ value: "" });
        this.vForm.workPreviousEmployerZipCode.push({ value: "" });
        this.vForm.workPreviousEmployerCountry.push({ value: "" });
        this.vForm.workPreviousEmployerTelephoneNumber.push({ value: "" });
        this.vForm.workPreviousEmployerJobTitle.push({ value: "" });
        this.vForm.workPreviousEmployerSupervisorGN.push({ value: "" });
        this.vForm.workPreviousEmployerSupervisorSN.push({ value: "" });
        this.vForm.workPreviousEmployerStartDate.push({ value: "" });
        this.vForm.workPreviousEmployerStartDateDay.push({ value: "" });
        this.vForm.workPreviousEmployerStartDateMonth.push({ value: "" });
        this.vForm.workPreviousEmployerStartDateYear.push({ value: "" });
        this.vForm.workPreviousEmployerEndDate.push({ value: "" });
        this.vForm.workPreviousEmployerEndDateDay.push({ value: "" });
        this.vForm.workPreviousEmployerEndDateMonth.push({ value: "" });
        this.vForm.workPreviousEmployerEndDateYear.push({ value: "" });
        this.vForm.workPreviousEmployerBrieflyDescribe.push({ value: "" });
      }
    },
    removePrevious() {
      if (!this.canNotRemove) {
        this.vForm.workPreviousEmployerName.pop();
        this.vForm.workPreviousEmployerStreetLine1.pop();
        this.vForm.workPreviousEmployerStreetLine2.pop();
        this.vForm.workPreviousEmployerCity.pop();
        this.vForm.workPreviousEmployerState.pop();
        this.vForm.workPreviousEmployerZipCode.pop();
        this.vForm.workPreviousEmployerCountry.pop();
        this.vForm.workPreviousEmployerTelephoneNumber.pop();
        this.vForm.workPreviousEmployerJobTitle.pop();
        this.vForm.workPreviousEmployerSupervisorGN.pop();
        this.vForm.workPreviousEmployerSupervisorSN.pop();
        this.vForm.workPreviousEmployerStartDate.pop();
        this.vForm.workPreviousEmployerStartDateDay.pop();
        this.vForm.workPreviousEmployerStartDateMonth.pop();
        this.vForm.workPreviousEmployerStartDateYear.pop();
        this.vForm.workPreviousEmployerEndDate.pop();
        this.vForm.workPreviousEmployerEndDateDay.pop();
        this.vForm.workPreviousEmployerEndDateMonth.pop();
        this.vForm.workPreviousEmployerEndDateYear.pop();
        this.vForm.workPreviousEmployerBrieflyDescribe.pop();
      }
    },
    addEducation() {
      if (!this.canNotAddEducation) {
        this.vForm.previousEducationInstitutionName.push({ value: "" });
        this.vForm.previousEducationInstitutionStreetLine1.push({ value: "" });
        this.vForm.previousEducationInstitutionStreetLine2.push({ value: "" });
        this.vForm.previousEducationInstitutionCity.push({ value: "" });
        this.vForm.previousEducationInstitutionState.push({ value: "" });
        this.vForm.previousEducationInstitutionZipCode.push({ value: "" });
        this.vForm.previousEducationInstitutionCountry.push({ value: "" });
        this.vForm.previousEducationInstitutionCourseStudy.push({ value: "" });
        this.vForm.previousEducationInstitutionStartDate.push({ value: "" });
        this.vForm.previousEducationInstitutionStartDateDay.push({ value: "" });
        this.vForm.previousEducationInstitutionStartDateMonth.push({
          value: "",
        });
        this.vForm.previousEducationInstitutionStartDateYear.push({
          value: "",
        });
        this.vForm.previousEducationInstitutionEndDateDay.push({ value: "" });
        this.vForm.previousEducationInstitutionEndDate.push({ value: "" });
        this.vForm.previousEducationInstitutionEndDateMonth.push({ value: "" });
        this.vForm.previousEducationInstitutionEndDateYear.push({ value: "" });
      }
    },
    removeEducation() {
      if (!this.canNotRemoveEducation) {
        this.vForm.previousEducationInstitutionName.pop();
        this.vForm.previousEducationInstitutionStreetLine1.pop();
        this.vForm.previousEducationInstitutionStreetLine2.pop();
        this.vForm.previousEducationInstitutionCity.pop();
        this.vForm.previousEducationInstitutionState.pop();
        this.vForm.previousEducationInstitutionZipCode.pop();
        this.vForm.previousEducationInstitutionCountry.pop();
        this.vForm.previousEducationInstitutionCourseStudy.pop();
        this.vForm.previousEducationInstitutionStartDate.pop();
        this.vForm.previousEducationInstitutionStartDateDay.pop();
        this.vForm.previousEducationInstitutionStartDateMonth.pop();
        this.vForm.previousEducationInstitutionStartDateYear.pop();
        this.vForm.previousEducationInstitutionEndDateDay.pop();
        this.vForm.previousEducationInstitutionEndDate.pop();
        this.vForm.previousEducationInstitutionEndDateMonth.pop();
        this.vForm.previousEducationInstitutionEndDateYear.pop();
      }
    },
    handleWorkStartDate(val) {
      var check = moment(val, "YYYY/MM/DD");

      var month = check.format("MM");
      var day = check.format("DD");
      var year = check.format("YYYY");

      this.vForm.workPresentStartDateDay = day;
      this.vForm.workPresentStartDateMonth = month;
      this.vForm.workPresentStartDateYear = year;
    },
    handlePreviousWorkStartDate(val, amount) {
      var check = moment(val, "YYYY/MM/DD");

      var month = check.format("MM");
      var day = check.format("DD");
      var year = check.format("YYYY");

      this.vForm.workPreviousEmployerStartDateDay[amount].value = day;
      this.vForm.workPreviousEmployerStartDateMonth[amount].value = month;
      this.vForm.workPreviousEmployerStartDateYear[amount].value = year;
    },
    handlePreviousStudyStartDate(val, amount) {
      var check = moment(val, "YYYY/MM/DD");

      var month = check.format("MM");
      var day = check.format("DD");
      var year = check.format("YYYY");

      this.vForm.previousEducationInstitutionStartDateDay[amount].value = day;
      this.vForm.previousEducationInstitutionStartDateMonth[amount].value =
        month;
      this.vForm.previousEducationInstitutionStartDateYear[amount].value = year;
    },

    handlePreviousStudyEndDate(val, amount) {
      var check = moment(val, "YYYY/MM/DD");

      var month = check.format("MM");
      var day = check.format("DD");
      var year = check.format("YYYY");

      this.vForm.previousEducationInstitutionEndDateDay[amount].value = day;
      this.vForm.previousEducationInstitutionEndDateMonth[amount].value = month;
      this.vForm.previousEducationInstitutionEndDateYear[amount].value = year;
    },
    handlePreviousWorkEndDate(val, amount) {
      var check = moment(val, "YYYY/MM/DD");

      var month = check.format("MM");
      var day = check.format("DD");
      var year = check.format("YYYY");

      this.vForm.workPreviousEmployerEndDateDay[amount].value = day;
      this.vForm.workPreviousEmployerEndDateMonth[amount].value = month;
      this.vForm.workPreviousEmployerEndDateYear[amount].value = year;
    },
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
