<template>
  <div :class="$style.content">
    <a-list
      item-layout="vertical"
      size="small"
      :data-source="suggestions.items"
      :pagination="suggestions.pagination"
    >
      <template #renderItem="{ item }">
        <a-list-item
          :key="item.id"
          :class="selectedSuggestion == item.id ? $style.itemSelected : $style.item"
          @click="setSelected(item.id)"
        >
          <template #actions>
            <span v-for="{ type, text } in item.extra" :key="type">
              <component v-bind:is="type" style="margin-right: 8px" />
              {{ text }}
            </span>
          </template>
          <template #extra>
            <img v-if="!!item.imagem" width="200" :alt="item.name" :src="item.imagem" />
          </template>
          <a-list-item-meta>
            <template #title>
              <a @click="setSelected(item.id)">{{ item.name }}</a>
            </template>
          </a-list-item-meta>
          <p v-html="item.description"></p>
        </a-list-item>
      </template>
    </a-list>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import { defineComponent } from "vue";
import { StarOutlined } from "@ant-design/icons-vue";

import { isDesktop } from "@/utils/isAgent";

export default defineComponent({
  components: { StarOutlined },
  props: {
    suggestions: Array,
  },
  data() {
    return {
      isDesktop,
    };
  },
  computed: {
    ...mapState("application", ["vForm"]),
    selectedSuggestion() {
      return this.vForm.selectedTravelSuggestion;
    },
  },
  methods: {
    ...mapMutations("application", ["setApplicationSuggestion"]),
    setSelected(id) {
      const selectedPreset = this.suggestions.items.find((e) => e.id == id);
      this.setApplicationSuggestion(selectedPreset);
    },
  },
  async mounted() {},
});
</script>

<style module src="./style.scss" lang="scss"></style>
