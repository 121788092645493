import moment from "moment";

export const validateDate = async (rule, value) => {
  if (!value) {
    return Promise.reject("Campo obrigatório");
  }
  return Promise.resolve();
};

export const validateDateEarlyThenDate = async (rule, value, date) => {
  if (!value) {
    return Promise.reject("Campo obrigatório");
  }

  const momentDate = moment(date);

  const valueDate = moment(value);

  if (valueDate < momentDate) {
    return Promise.reject(`A data deve ser antes de ${value}`);
  }
  return Promise.resolve();
};

export const validateDateAfterThenDate = async (rule, value, date) => {
  if (!value) {
    return Promise.reject("Campo obrigatório");
  }

  const momentDate = moment(date);

  const valueDate = moment(value);

  if (valueDate > momentDate) {
    return Promise.reject(`A data deve ser depois de ${value}`);
  }
  return Promise.resolve();
};

export const validateDateEarlyThen = async (rule, value, maxAge) => {
  if (!value) {
    return Promise.reject("Campo obrigatório");
  }

  if (moment().diff(value, "years", false) > maxAge) {
    return Promise.reject(`A data deve ser menor que ${maxAge} anos`);
  }
  return Promise.resolve();
};

export const alwaysTrue = async () => {
  return Promise.resolve();
};
