<template>
  <a-row>
    <a-col span="24">
      <a-col span="24">
        <a-form-item
          ref="previousHasTraveledOthersCountries"
          label="Você viajou para algum país/região nos últimos cinco anos?"
          required
          name="previousHasTraveledOthersCountries"
        >
          <a-radio-group
            v-model:value="vForm.previousHasTraveledOthersCountries"
            :readonly="readOnlyForm"
          >
            <a-radio-button value="Y">Sim</a-radio-button>
            <a-radio-button value="N">Não</a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-col>
      <div v-if="vForm.previousHasTraveledOthersCountries == 'Y'">
        <a-col span="24">
          <a-row>
            <a-col span="24" :class="[$style.space]">
              <span
                >Forneça informações sobre seus últimos 5 anos de viagem no
                exterior:</span
              >
            </a-col>
            <a-col
              span="24"
              :class="[$style.space, $style.blueSpace, $style.outSpace]"
              v-for="(item, amount) in vForm.previousTraveledOthersCountries"
              :key="amount"
            >
              <a-col span="24">
                <MultiCountrySelect
                  reference="previousTraveledOthersCountries"
                  name="País"
                  :identification="amount"
                  :isRequired="vForm.previousHasTraveledOthersCountries == 'Y'"
                />
              </a-col>
            </a-col>
            <a-col span="24" :class="$style.space">
              <a-button
                type="primary"
                size="small"
                :disabled="canNotAddPrevious"
                @click="addPrevious()"
              >
                <template #icon>
                  <PlusOutlined />
                </template>
                Adicionar mais
              </a-button>
              <a-button
                type="danger"
                size="small"
                @click="rmPrevious()"
                :disabled="canNotRemovePrevious"
                :class="$style.outSpace"
              >
                <template #icon>
                  <MinusOutlined />
                </template>
                Remover
              </a-button>
            </a-col>
          </a-row>
        </a-col>
      </div>
    </a-col>
  </a-row>
</template>
<script>
import { defineComponent } from "vue";
import { mapMutations, mapState, mapGetters } from "vuex";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons-vue";
import MultiCountrySelect from "@/components/CountrySelect/MultiCountrySelect";
import { DEFAULT_DATE_FORMAT, DEFAULT_MAX_ADD_AMOUNT } from "@/utils/Constants";
import { isDesktop } from "@/utils/isAgent";

export default defineComponent({
  components: { PlusOutlined, MultiCountrySelect, MinusOutlined },
  data() {
    return { DEFAULT_DATE_FORMAT, isDesktop };
  },
  computed: {
    ...mapState("application", ["vForm"]),
    ...mapGetters("application", ["readOnlyForm"]),
    canNotAddPrevious() {
      return !(
        this.vForm.previousTraveledOthersCountries.length <
        DEFAULT_MAX_ADD_AMOUNT
      );
    },
    canNotRemovePrevious() {
      return !(this.vForm.previousTraveledOthersCountries.length > 1);
    },
  },
  methods: {
    ...mapMutations("application", ["setSaveFilledForm"]),
    addPrevious() {
      if (!this.canNotAddPrevious) {
        this.vForm.previousTraveledOthersCountries.push({ value: "" });
      }
    },
    rmPrevious() {
      if (this.vForm.previousTraveledOthersCountries.length > 1) {
        this.vForm.previousTraveledOthersCountries.pop();
      }
    },
  },
  mounted() {},
});
</script>

<style module src="./style.scss" lang="scss"></style>
