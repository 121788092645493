<template>
  <a-row>
    <a-col span="24">
      <a-collapse v-model:activeKey="activeKey">
        <template #expandIcon="{ isActive }">
          <RightOutlined :rotate="isActive ? 90 : 0" />
        </template>
        <a-collapse-panel
          showArrow
          key="1"
          header="Parentesco"
          :class="$style.customStyle"
        >
          <a-col span="24">
            <div :class="[$style.space, $style.addMore, $style.outSpace]">
              <span :class="$style.dataType">Dados do Pai</span>
              <a-row>
                <a-col :span="isDesktop ? 8 : 24" :class="$style.space">
                  <a-form-item
                    ref="familyRelativesFatherGivenName"
                    label="Nome"
                    name="familyRelativesFatherGivenName"
                  >
                    <a-input
                      v-model:value="vForm.familyRelativesFatherGivenName"
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col :span="isDesktop ? 8 : 12" :class="$style.space">
                  <a-form-item
                    ref="familyRelativesFatherSurname"
                    label="Sobrenome"
                    name="familyRelativesFatherSurname"
                    :rules="{
                      required: needFillFather,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                    }"
                  >
                    <a-input
                      v-model:value="vForm.familyRelativesFatherSurname"
                      :disabled="!needFillFather"
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>

                <a-col :span="isDesktop ? 8 : 12" :class="$style.space">
                  <a-form-item
                    ref="fatherBirthDate"
                    label="Data de Nascimento"
                    name="fatherBirthDate"
                    :rules="{
                      required: false,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                      validator: alwaysTrue,
                    }"
                  >
                    <a-date-picker
                      :disabled="!needFillFather"
                      v-model:value="vForm.fatherBirthDate"
                      :readonly="readOnlyForm"
                      type="date"
                      placeholder="Selecione"
                      style="width: 100%"
                      @change="handleFatherBirthDate"
                      :disabled-date="aboveCurrentDate"
                      :format="DEFAULT_DATE_FORMAT"
                    />
                  </a-form-item>
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-form-item
                    ref="familyRelativesFatherIsInUS"
                    label="Seu pai está no Estados Unidos?"
                    name="familyRelativesFatherIsInUS"
                    :rules="{
                      required: needFillFather,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                    }"
                  >
                    <a-radio-group
                      :disabled="!needFillFather"
                      v-model:value="vForm.familyRelativesFatherIsInUS"
                      :readonly="readOnlyForm"
                    >
                      <a-radio-button value="Y">Sim</a-radio-button>
                      <a-radio-button value="N">Não</a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col span="24">
                  <div v-if="vForm.familyRelativesFatherIsInUS == 'Y'">
                    <a-col span="24" :class="[$style.space, $style.addMore]">
                      <a-form-item
                        ref="familyRelativesFatherStatus"
                        label="Status imigratório do seu pai"
                        name="familyRelativesFatherStatus"
                        :required="vForm.familyRelativesFatherIsInUS == 'Y'"
                      >
                        <a-select
                          placeholder="Selecione"
                          v-model:value="vForm.familyRelativesFatherStatus"
                          :readonly="readOnlyForm"
                        >
                          <a-select-option value="S"
                            >Cidadão Americano</a-select-option
                          >
                          <a-select-option value="C"
                            >Residente Americano Permanente
                            (LPR)</a-select-option
                          >
                          <a-select-option value="P"
                            >Não Imigrante</a-select-option
                          >
                          <a-select-option value="O"
                            >Outro/Não sei</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </div>
                </a-col>
              </a-row>
            </div>
            <div :class="[$style.space, $style.addMore, $style.outSpace]">
              <span :class="$style.dataType">Dados da Mãe</span>
              <a-row>
                <a-col :span="isDesktop ? 8 : 24" :class="$style.space">
                  <a-form-item
                    ref="familyRelativesMotherGivenName"
                    label="Nome"
                    name="familyRelativesMotherGivenName"
                  >
                    <a-input
                      v-model:value="vForm.familyRelativesMotherGivenName"
                      :readonly="readOnlyForm"
                    >
                      <template #suffix>
                        <a-tooltip :title="PASSPORT_WITHOUT_NAME">
                          <info-circle-outlined
                            style="color: rgba(0, 0, 0, 0.45)"
                          />
                        </a-tooltip>
                      </template>
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col :span="isDesktop ? 8 : 12" :class="$style.space">
                  <a-form-item
                    ref="familyRelativesMotherSurname"
                    label="Sobrenome"
                    name="familyRelativesMotherSurname"
                    :rules="{
                      required: needFillMother,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                    }"
                  >
                    <a-input
                      v-model:value="vForm.familyRelativesMotherSurname"
                      :disabled="!needFillMother"
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>

                <a-col :span="isDesktop ? 8 : 12" :class="$style.space">
                  <a-form-item
                    ref="motherBirthDate"
                    label="Data de Nascimento"
                    name="motherBirthDate"
                    :rules="{
                      required: false,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                      validator: alwaysTrue,
                    }"
                  >
                    <a-date-picker
                      v-model:value="vForm.motherBirthDate"
                      type="date"
                      placeholder="Selecione"
                      style="width: 100%"
                      @change="handleMotherBirthDate"
                      :disabled-date="aboveCurrentDate"
                      :readonly="readOnlyForm"
                      :disabled="!needFillMother"
                      :format="DEFAULT_DATE_FORMAT"
                    />
                  </a-form-item>
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-form-item
                    ref="familyRelativesMotherIsInUS"
                    label="Sua mãe está no Estados Unidos?"
                    name="familyRelativesMotherIsInUS"
                    :rules="{
                      required: needFillMother,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                    }"
                  >
                    <a-radio-group
                      :disabled="!needFillMother"
                      v-model:value="vForm.familyRelativesMotherIsInUS"
                      :readonly="readOnlyForm"
                    >
                      <a-radio-button value="Y">Sim</a-radio-button>
                      <a-radio-button value="N">Não</a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col span="24">
                  <div v-if="vForm.familyRelativesMotherIsInUS == 'Y'">
                    <a-col span="24" :class="[$style.space, $style.addMore]">
                      <a-form-item
                        ref="familyRelativesMotherStatus"
                        label="Status imigratório da sua mãe"
                        name="familyRelativesMotherStatus"
                        :required="vForm.familyRelativesMotherIsInUS == 'Y'"
                      >
                        <a-select
                          placeholder="Selecione"
                          v-model:value="vForm.familyRelativesMotherStatus"
                          :readonly="readOnlyForm"
                        >
                          <a-select-option value="S"
                            >Cidadão Americano</a-select-option
                          >
                          <a-select-option value="C"
                            >Residente Americano Permanente
                            (LPR)</a-select-option
                          >
                          <a-select-option value="P"
                            >Não Imigrante</a-select-option
                          >
                          <a-select-option value="O"
                            >Outro/Não sei</a-select-option
                          >
                        </a-select>
                      </a-form-item>
                    </a-col>
                  </div>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-collapse-panel>
        <a-collapse-panel
          v-if="showSpouse"
          showArrow
          key="2"
          :header="nameMarried"
          :class="$style.customStyle"
        >
          <a-col span="24">
            <a-row>
              <a-col span="8" :class="$style.space">
                <a-form-item
                  ref="familySpouseGivenName"
                  label="Nome"
                  name="familySpouseGivenName"
                  :rules="{
                    required: showSpouse,
                    message: 'Campo obrigatório',
                    trigger: 'blur',
                  }"
                >
                  <a-input
                    v-model:value="vForm.familySpouseGivenName"
                    :readonly="readOnlyForm"
                  >
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col span="8" :class="$style.space">
                <a-form-item
                  ref="familySpouseSurname"
                  label="Sobrenome"
                  name="familySpouseSurname"
                  :rules="{
                    required: showSpouse,
                    message: 'Campo obrigatório',
                    trigger: 'blur',
                  }"
                >
                  <a-input
                    v-model:value="vForm.familySpouseSurname"
                    :readonly="readOnlyForm"
                  >
                  </a-input>
                </a-form-item>
              </a-col>

              <a-col span="8" :class="$style.space">
                <a-form-item
                  ref="familySpouseBirth"
                  label="Data de Nascimento"
                  :rules="{
                    required: showSpouse,
                    message: 'Campo obrigatório',
                    trigger: 'blur',
                    validator: validateDate,
                  }"
                  name="familySpouseBirth"
                >
                  <a-date-picker
                    v-model:value="vForm.familySpouseBirth"
                    type="date"
                    placeholder="Selecione"
                    style="width: 100%"
                    :disabled-date="aboveCurrentDate"
                    @change="handleBirthDate"
                    :readonly="readOnlyForm"
                    :format="DEFAULT_DATE_FORMAT"
                  />
                </a-form-item>
              </a-col>

              <a-col span="24" :class="$style.space">
                <CountrySelect
                  reference="familySpouseNationality"
                  name="Nacionalidade"
                  :isRequired="showSpouse" />
                <a-col span="24">
                  <div>
                    <a-col span="24" :class="[$style.space, $style.addMore]">
                      <a-row>
                        <a-col span="12" :class="$style.space">
                          <a-form-item
                            ref="familySpouseBirthCity"
                            label="Cidade em que reside atualmente"
                            name="familySpouseBirthCity"
                            :rules="{
                              required: showSpouse,
                              message: 'Campo obrigatório',
                              trigger: 'blur',
                            }"
                          >
                            <a-input
                              v-model:value="vForm.familySpouseBirthCity"
                              :readonly="readOnlyForm"
                            >
                            </a-input>
                          </a-form-item>
                        </a-col>
                        <a-col span="12" :class="$style.space">
                          <CountrySelect
                            reference="familySpouseBirthCountry"
                            name="País em que reside atualmente"
                            :isRequired="showSpouse"
                          />
                        </a-col>
                      </a-row>
                    </a-col>
                  </div> </a-col
              ></a-col>
            </a-row>
          </a-col>
        </a-collapse-panel>
        <a-collapse-panel
          v-if="isWidowed"
          showArrow
          key="2"
          header="Viúvo(a)"
          :class="$style.customStyle"
        >
          <a-col span="24">
            <a-row>
              <a-col span="8" :class="$style.space">
                <a-form-item
                  ref="familyDeceasedSpouseGivenName"
                  label="Nome"
                  name="familyDeceasedSpouseGivenName"
                  :rules="{
                    required: isWidowed,
                    message: 'Campo obrigatório',
                    trigger: 'blur',
                  }"
                >
                  <a-input
                    v-model:value="vForm.familyDeceasedSpouseGivenName"
                    :readonly="readOnlyForm"
                  >
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col span="8" :class="$style.space">
                <a-form-item
                  ref="familyDeceasedSpouseSurname"
                  label="Sobrenome"
                  name="familyDeceasedSpouseSurname"
                  :rules="{
                    required: isWidowed,
                    message: 'Campo obrigatório',
                    trigger: 'blur',
                  }"
                >
                  <a-input
                    v-model:value="vForm.familyDeceasedSpouseSurname"
                    :readonly="readOnlyForm"
                  >
                  </a-input>
                </a-form-item>
              </a-col>

              <a-col span="8" :class="$style.space">
                <a-form-item
                  ref="familyDeceasedSpouseBirth"
                  label="Data de Nascimento"
                  :rules="{
                    required: isWidowed,
                    message: 'Campo obrigatório',
                    trigger: 'blur',
                    validator: validateDate,
                  }"
                  name="familyDeceasedSpouseBirth"
                >
                  <a-date-picker
                    v-model:value="vForm.familyDeceasedSpouseBirth"
                    type="date"
                    placeholder="Selecione"
                    style="width: 100%"
                    :disabled-date="aboveCurrentDate"
                    @change="handleBirthDateWidowed"
                    :readonly="readOnlyForm"
                    :format="DEFAULT_DATE_FORMAT"
                  />
                </a-form-item>
              </a-col>

              <a-col span="24" :class="$style.space">
                <CountrySelect
                  reference="familyDeceasedSpouseNationality"
                  name="Nacionalidade"
                  :isRequired="isWidowed" />
                <a-col span="24">
                  <div>
                    <a-col span="24" :class="[$style.space, $style.addMore]">
                      <a-row>
                        <a-col span="12" :class="$style.space">
                          <a-form-item
                            ref="familyDeceasedSpouseBirthCity"
                            label="Cidade em que faleceu"
                            name="familyDeceasedSpouseBirthCity"
                          >
                            <a-input
                              v-model:value="
                                vForm.familyDeceasedSpouseBirthCity
                              "
                              :readonly="readOnlyForm"
                            >
                            </a-input>
                          </a-form-item>
                        </a-col>
                        <a-col span="12" :class="$style.space">
                          <CountrySelect
                            reference="familyDeceasedSpouseBirthCountry"
                            name="Local em que faleceu"
                            :isRequired="isWidowed"
                          />
                        </a-col>
                      </a-row>
                    </a-col>
                  </div> </a-col
              ></a-col>
            </a-row>
          </a-col>
        </a-collapse-panel>
        <a-collapse-panel
          v-if="isDivorced"
          showArrow
          key="2"
          header="Ex-cônjuge"
          :class="$style.customStyle"
        >
          <a-col span="24">
            <a-col
              span="24"
              :class="[$style.space, $style.addMore]"
              v-for="(item, amount) in vForm.familyFormerSpouseGivenName"
              :key="amount"
            >
              <a-row>
                <a-col span="8" :class="$style.space">
                  <a-form-item
                    ref="familyFormerSpouseGivenName"
                    label="Nome"
                    :name="['familyFormerSpouseGivenName', amount, 'value']"
                    :rules="{
                      required: isDivorced,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                    }"
                  >
                    <a-input
                      v-model:value="
                        vForm.familyFormerSpouseGivenName[amount].value
                      "
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col span="8" :class="$style.space">
                  <a-form-item
                    ref="familyFormerSpouseSurname"
                    label="Sobrenome"
                    :name="['familyFormerSpouseSurname', amount, 'value']"
                    :rules="{
                      required: isDivorced,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                    }"
                  >
                    <a-input
                      v-model:value="
                        vForm.familyFormerSpouseSurname[amount].value
                      "
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>

                <a-col span="8" :class="$style.space">
                  <a-form-item
                    ref="familyFormerSpouseBirth"
                    label="Data de Nascimento"
                    :rules="{
                      required: isDivorced,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                      validator: validateDate,
                    }"
                    :name="['familyFormerSpouseBirth', amount, 'value']"
                  >
                    <a-date-picker
                      v-model:value="
                        vForm.familyFormerSpouseBirth[amount].value
                      "
                      type="date"
                      placeholder="Selecione"
                      style="width: 100%"
                      :disabled-date="aboveCurrentDate"
                      @change="(val) => handleFormerBirthDate(val, amount)"
                      :readonly="readOnlyForm"
                      :format="DEFAULT_DATE_FORMAT"
                    />
                  </a-form-item>
                </a-col>

                <a-col span="24" :class="$style.space">
                  <MultiCountrySelect
                    reference="familyFormerSpouseNationality"
                    name="Nacionalidade"
                    :identification="amount"
                    :isRequired="isDivorced" />
                  <a-col span="24">
                    <div>
                      <a-col
                        span="24"
                        :class="[$style.space, $style.whiteSpace]"
                      >
                        <a-row>
                          <a-col span="12" :class="$style.space">
                            <a-form-item
                              ref="familyFormerSpouseBirthCity"
                              label="Cidade em que reside atualmente"
                              :name="[
                                'familyFormerSpouseBirthCity',
                                amount,
                                'value',
                              ]"
                              :rules="{
                                required: isDivorced,
                                message: 'Campo obrigatório',
                                trigger: 'blur',
                              }"
                            >
                              <a-input
                                v-model:value="
                                  vForm.familyFormerSpouseBirthCity[amount]
                                    .value
                                "
                                :readonly="readOnlyForm"
                              >
                              </a-input>
                            </a-form-item>
                          </a-col>
                          <a-col span="12" :class="$style.space">
                            <MultiCountrySelect
                              reference="familyFormerSpouseBirthRegion"
                              name="País de nascimento"
                              :identification="amount"
                              :isRequired="isDivorced"
                            />
                          </a-col>
                        </a-row>
                      </a-col>
                    </div> </a-col
                ></a-col>
                <a-col span="12" :class="$style.space">
                  <a-form-item
                    ref="familyFormerSpouseDataMarriageStart"
                    label="Data do casamento"
                    :rules="{
                      required: isDivorced,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                      validator: validateDate,
                    }"
                    :name="[
                      'familyFormerSpouseDataMarriageStart',
                      amount,
                      'value',
                    ]"
                  >
                    <a-date-picker
                      v-model:value="
                        vForm.familyFormerSpouseDataMarriageStart[amount].value
                      "
                      type="date"
                      placeholder="Selecione"
                      style="width: 100%"
                      :disabled-date="aboveCurrentDate"
                      @change="
                        (val) => handleFormerMariageStartDate(val, amount)
                      "
                      :readonly="readOnlyForm"
                      :format="DEFAULT_DATE_FORMAT"
                    />
                  </a-form-item>
                </a-col>
                <a-col span="12" :class="$style.space">
                  <a-form-item
                    ref="familyFormerSpouseDataMarriageEnd"
                    label="Data do término"
                    :rules="{
                      required: isDivorced,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                      validator: validateDate,
                    }"
                    :name="[
                      'familyFormerSpouseDataMarriageEnd',
                      amount,
                      'value',
                    ]"
                  >
                    <a-date-picker
                      v-model:value="
                        vForm.familyFormerSpouseDataMarriageEnd[amount].value
                      "
                      type="date"
                      placeholder="Selecione"
                      style="width: 100%"
                      :disabled-date="aboveCurrentDate"
                      @change="(val) => handleFormerMariageEndDate(val, amount)"
                      :readonly="readOnlyForm"
                      :format="DEFAULT_DATE_FORMAT"
                    />
                  </a-form-item>
                </a-col>
                <a-col span="24">
                  <a-col span="24" :class="[$style.space]">
                    <a-form-item
                      label="Por que separou?"
                      :name="[
                        'familyFormerSpouseEndMarriedExplain',
                        amount,
                        'value',
                      ]"
                      :rules="{
                        required: isDivorced,
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                      }"
                    >
                      <a-textarea
                        v-model:value="
                          vForm.familyFormerSpouseEndMarriedExplain[amount]
                            .value
                        "
                        placeholder="Explique"
                        :readonly="readOnlyForm"
                        :rows="4"
                      />
                    </a-form-item>
                  </a-col>
                </a-col>
                <a-col span="24" :class="$style.space">
                  <MultiCountrySelect
                    reference="familyFormerSpouseCountry"
                    name="País em que se separou"
                    :identification="amount"
                    :isRequired="isDivorced"
                  />
                </a-col>
              </a-row>
            </a-col>
            <a-col span="24" :class="$style.space">
              <a-button
                type="primary"
                :class="$style.outSpace"
                size="small"
                :disabled="canNotAddMoreFormer"
                @click="addFormer()"
              >
                <template #icon>
                  <PlusOutlined />
                </template>
                Adicionar mais
              </a-button>
              <a-button
                type="danger"
                size="small"
                @click="removeFormer()"
                :disabled="canNotRemoveFormer"
                :class="$style.outSpace"
              >
                <template #icon>
                  <MinusOutlined />
                </template>
                Remover
              </a-button>
            </a-col>
          </a-col>
        </a-collapse-panel>
      </a-collapse>
    </a-col>
  </a-row>
</template>
<script>
import { defineComponent } from "vue";
import { mapMutations, mapState, mapGetters } from "vuex";
import { PASSPORT_WITHOUT_NAME } from "@/utils/tooltipConstants";
import { DEFAULT_DATE_FORMAT } from "@/utils/Constants";
import { RightOutlined } from "@ant-design/icons-vue";
import CountrySelect from "@/components/CountrySelect/CountrySelect";
import MultiCountrySelect from "@/components/CountrySelect/MultiCountrySelect";
import { validateDate, alwaysTrue } from "@/utils/validation";
import moment from "moment";

export default defineComponent({
  data() {
    return {
      activeKey: ["1"],
      DEFAULT_DATE_FORMAT,
      PASSPORT_WITHOUT_NAME,
      validateDate,
      alwaysTrue,
      MAX_DIVORCED: 5,
    };
  },
  components: { RightOutlined, CountrySelect, MultiCountrySelect },
  computed: {
    ...mapGetters("application", ["readOnlyForm"]),
    ...mapState("application", ["vForm"]),
    isMarried() {
      return this.vForm.personal1Marital == "M";
    },
    isWidowed() {
      return this.vForm.personal1Marital == "W";
    },
    isLegally() {
      return this.vForm.personal1Marital == "L";
    },
    isDivorced() {
      return this.vForm.personal1Marital == "D";
    },
    isCommumLaw() {
      return this.vForm.personal1Marital == "C";
    },
    isPartner() {
      return this.vForm.personal1Marital == "P";
    },
    showSpouse() {
      return (
        this.isMarried || this.isCommumLaw || this.isPartner || this.isLegally
      );
    },
    nameMarried() {
      return this.isMarried || this.isCommumLaw || this.isLegally
        ? "Esposo(a)"
        : "Parceira";
    },
    needFillFather() {
      return (
        !!this.vForm.familyRelativesFatherGivenName &&
        this.vForm.familyRelativesFatherGivenName != ""
      );
    },
    needFillMother() {
      return (
        !!this.vForm.familyRelativesMotherGivenName &&
        this.vForm.familyRelativesMotherGivenName != ""
      );
    },
    canNotAddMoreFormer() {
      return !(this.vForm.familyFormerSpouseSurname.length < this.MAX_DIVORCED);
    },
    canNotRemoveFormer() {
      return !(this.vForm.familyFormerSpouseSurname.length > 1);
    },
  },
  methods: {
    ...mapMutations("application", ["setSaveFilledForm"]),
    aboveCurrentDate(current) {
      return current && current > moment().endOf("day");
    },
    handleBirthDate(val) {
      if (val != "") {
        var check = moment(val, "YYYY/MM/DD");

        var month = check.format("MM");
        var day = check.format("DD");
        var year = check.format("YYYY");

        this.vForm.familySpouseBirthDay = day;
        this.vForm.familySpouseBirthMonth = month;
        this.vForm.familySpouseBirthYear = year;
      } else {
        this.vForm.familySpouseBirthDay = "";
        this.vForm.familySpouseBirthMonth = "";
        this.vForm.familySpouseBirthYear = "";
      }
    },
    handleBirthDateWidowed(val) {
      if (val != "") {
        var check = moment(val, "YYYY/MM/DD");

        var month = check.format("MM");
        var day = check.format("DD");
        var year = check.format("YYYY");

        this.vForm.familyDeceasedSpouseBirthDay = day;
        this.vForm.familyDeceasedSpouseBirthMonth = month;
        this.vForm.familyDeceasedSpouseBirthYear = year;
      } else {
        this.vForm.familyDeceasedSpouseBirthDay = "";
        this.vForm.familyDeceasedSpouseBirthMonth = "";
        this.vForm.familyDeceasedSpouseBirthYear = "";
      }
    },
    handleFatherBirthDate(val) {
      if (val && val != "") {
        var check = moment(val, "YYYY/MM/DD");

        var month = check.format("MM");
        var day = check.format("DD");
        var year = check.format("YYYY");

        this.vForm.familyRelativesFatherBirthDay = day;
        this.vForm.familyRelativesFatherBirthMonth = month;
        this.vForm.familyRelativesFatherBirthYear = year;
      } else {
        this.vForm.familyRelativesFatherBirthDay = "";
        this.vForm.familyRelativesFatherBirthMonth = "";
        this.vForm.familyRelativesFatherBirthYear = "";
      }
    },
    handleMotherBirthDate(val) {
      if (val && val != "") {
        var check = moment(val, "YYYY/MM/DD");

        var month = check.format("MM");
        var day = check.format("DD");
        var year = check.format("YYYY");

        this.vForm.familyRelativesMotherBirthDay = day;
        this.vForm.familyRelativesMotherBirthMonth = month;
        this.vForm.familyRelativesMotherBirthYear = year;
      } else {
        this.vForm.familyRelativesMotherBirthDay = "";
        this.vForm.familyRelativesMotherBirthMonth = "";
        this.vForm.familyRelativesMotherBirthYear = "";
      }
    },
    handleFormerBirthDate(val, amount) {
      if (val != "") {
        var check = moment(val, "YYYY/MM/DD");

        var month = check.format("MM");
        var day = check.format("DD");
        var year = check.format("YYYY");

        this.vForm.familyFormerSpouseBirthDay[amount].value = day;
        this.vForm.familyFormerSpouseBirthMonth[amount].value = month;
        this.vForm.familyFormerSpouseBirthYear[amount].value = year;
      } else {
        this.vForm.familyFormerSpouseBirthDay[amount].value = "";
        this.vForm.familyFormerSpouseBirthMonth[amount].value = "";
        this.vForm.familyFormerSpouseBirthYear[amount].value = "";
      }
    },
    handleFormerMariageStartDate(val, amount) {
      if (val != "") {
        var check = moment(val, "YYYY/MM/DD");

        var month = check.format("MM");
        var day = check.format("DD");
        var year = check.format("YYYY");

        this.vForm.familyFormerSpouseDataMarriageStartDay[amount].value = day;
        this.vForm.familyFormerSpouseDataMarriageStartMonth[amount].value =
          month;
        this.vForm.familyFormerSpouseDataMarriageStartYear[amount].value = year;
      } else {
        this.vForm.familyFormerSpouseDataMarriageStartDay[amount].value = "";
        this.vForm.familyFormerSpouseDataMarriageStartMonth[amount].value = "";
        this.vForm.familyFormerSpouseDataMarriageStartYear[amount].value = "";
      }
    },
    handleFormerMariageEndDate(val, amount) {
      if (val != "") {
        var check = moment(val, "YYYY/MM/DD");

        var month = check.format("MM");
        var day = check.format("DD");
        var year = check.format("YYYY");

        this.vForm.familyFormerSpouseDataMarriageEndDay[amount].value = day;
        this.vForm.familyFormerSpouseDataMarriageEndMonth[amount].value = month;
        this.vForm.familyFormerSpouseDataMarriageEndYear[amount].value = year;
      } else {
        this.vForm.familyFormerSpouseDataMarriageEndDay[amount].value = "";
        this.vForm.familyFormerSpouseDataMarriageEndMonth[amount].value = "";
        this.vForm.familyFormerSpouseDataMarriageEndYear[amount].value = "";
      }
    },
    addFormer() {
      if (!this.canNotAddMoreFormer) {
        this.vForm.familyFormerSpouseSurname.push({ value: "" });
        this.vForm.familyFormerSpouseGivenName.push({ value: "" });
        this.vForm.familyFormerSpouseBirthDay.push({ value: "" });
        this.vForm.familyFormerSpouseBirthMonth.push({ value: "" });
        this.vForm.familyFormerSpouseBirthYear.push({ value: "" });
        this.vForm.familyFormerSpouseNationality.push({ value: "" });
        this.vForm.familyFormerSpouseBirthCity.push({ value: "" });
        this.vForm.familyFormerSpouseBirthCityNotApply.push({
          value: "",
        });
        this.vForm.familyFormerSpouseBirthRegion.push({ value: "" });
        this.vForm.familyFormerSpouseDataMarriageStart.push({
          value: "",
        });
        this.vForm.familyFormerSpouseDataMarriageStartDay.push({
          value: "",
        });
        this.vForm.familyFormerSpouseDataMarriageStartMonth.push({
          value: "",
        });
        this.vForm.familyFormerSpouseDataMarriageStartYear.push({
          value: "",
        });
        this.vForm.familyFormerSpouseDataMarriageEndDay.push({
          value: "",
        });
        this.vForm.familyFormerSpouseDataMarriageEndMonth.push({
          value: "",
        });
        this.vForm.familyFormerSpouseDataMarriageEnd.push({
          value: "",
        });
        this.vForm.familyFormerSpouseDataMarriageEndYear.push({
          value: "",
        });
        this.vForm.familyFormerSpouseEndMarriedExplain.push({
          value: "",
        });
        this.vForm.familyFormerSpouseCountry.push({ value: "" });
        this.vForm.familyFormerSpouseBirth.push({ value: "" });
      }
    },
    removeFormer() {
      if (!this.canNotRemoveFormer) {
        this.vForm.familyFormerSpouseSurname.pop();
        this.vForm.familyFormerSpouseGivenName.pop();
        this.vForm.familyFormerSpouseBirthDay.pop();
        this.vForm.familyFormerSpouseBirthMonth.pop();
        this.vForm.familyFormerSpouseDataMarriageStart.pop();
        this.vForm.familyFormerSpouseDataMarriageEnd.pop();
        this.vForm.familyFormerSpouseBirthYear.pop();
        this.vForm.familyFormerSpouseNationality.pop();
        this.vForm.familyFormerSpouseBirthCity.pop();
        this.vForm.familyFormerSpouseBirthCityNotApply.pop();
        this.vForm.familyFormerSpouseBirthRegion.pop();
        this.vForm.familyFormerSpouseDataMarriageStartDay.pop();
        this.vForm.familyFormerSpouseDataMarriageStartMonth.pop();
        this.vForm.familyFormerSpouseDataMarriageStartYear.pop();
        this.vForm.familyFormerSpouseDataMarriageEndDay.pop();
        this.vForm.familyFormerSpouseDataMarriageEndMonth.pop();
        this.vForm.familyFormerSpouseDataMarriageEndYear.pop();
        this.vForm.familyFormerSpouseEndMarriedExplain.pop();
        this.vForm.familyFormerSpouseCountry.pop();
        this.vForm.familyFormerSpouseBirth.pop();
      }
    },
  },
  mounted() {
    // this.vForm.motherBirthDate = this.vForm.motherBirthDate
    //   ? moment(this.vForm.motherBirthDate)
    //   : "";
    // this.vForm.fatherBirthDate = this.vForm.fatherBirthDate
    //   ? moment(this.vForm.fatherBirthDate)
    //   : "";
    // this.vForm.familySpouseBirth = this.vForm.familySpouseBirth
    //   ? moment(this.vForm.familySpouseBirth)
    //   : "";
    // if (!this.vForm.motherBirthDate) {
    //   this.vForm.familyRelativesMotherBirthDay = "";
    //   this.vForm.familyRelativesMotherBirthMonth = "";
    //   this.vForm.familyRelativesMotherBirthYear = "";
    // }
    // if (!this.vForm.fatherBirthDate) {
    //   this.vForm.familyRelativesFatherBirthDay = "";
    //   this.vForm.familyRelativesFatherBirthMonth = "";
    //   this.vForm.familyRelativesFatherBirthYear = "";
    // }
  },
  watch: {
    showSpouse() {
      this.activeKey = this.showSpouse ? ["1", "2"] : ["1"];
    },
    isDivorced() {
      this.activeKey = this.isDivorced ? ["1", "2"] : ["1"];
    },
    isWidowed() {
      this.activeKey = this.isWidowed ? ["1", "2"] : ["1"];
    },
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
