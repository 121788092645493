<template>
  <a-row>
    <a-col span="24">
      <a-row>
        <a-col span="24" :class="$style.space">
          <CountrySelect
            reference="addressAndPhoneCountry"
            name="País"
            :isRequired="true"
          />
        </a-col>
        <a-col span="24" :class="$style.space">
          <a-form-item
            ref="addressAndPhoneZipCode"
            label="CEP"
            name="addressAndPhoneZipCode"
            :required="isBrazilian"
          >
            <a-input
              @change="getUserAddress()"
              v-maska="isBrazilian ? '#####-###' : '#*'"
              v-model:value="vForm.addressAndPhoneZipCode"
              :loading="isLoadingViaCep"
              :disabled="isLoadingViaCep"
              :readonly="readOnlyForm"
            >
            </a-input>
          </a-form-item>
        </a-col>
        <a-col span="12" :class="$style.space">
          <a-form-item
            ref="addressAndPhoneAddressLine1"
            label="Endereço"
            name="addressAndPhoneAddressLine1"
            :required="true"
          >
            <a-input
              v-model:value="vForm.addressAndPhoneAddressLine1"
              :disabled="isLoadingViaCep"
              :readonly="readOnlyForm"
            >
            </a-input>
          </a-form-item>
        </a-col>
        <a-col span="12" :class="$style.space">
          <a-form-item
            ref="addressAndPhoneAddressLine2"
            label="Complemento"
            name="addressAndPhoneAddressLine2"
          >
            <a-input
              v-model:value="vForm.addressAndPhoneAddressLine2"
              :disabled="isLoadingViaCep"
              :readonly="readOnlyForm"
            >
            </a-input>
          </a-form-item>
        </a-col>

        <a-col span="12" :class="$style.space">
          <a-form-item
            ref="addressAndPhoneCity"
            label="Cidade"
            name="addressAndPhoneCity"
            :required="true"
          >
            <a-input
              v-model:value="vForm.addressAndPhoneCity"
              :disabled="isLoadingViaCep"
              :readonly="readOnlyForm"
            >
            </a-input>
          </a-form-item>
        </a-col>
        <a-col span="12" :class="$style.space">
          <a-form-item
            ref="addressAndPhoneState"
            label="Estado"
            :required="true"
            name="addressAndPhoneState"
          >
            <a-input
              v-model:value="vForm.addressAndPhoneState"
              :disabled="isLoadingViaCep"
              :readonly="readOnlyForm"
            >
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-divider />
      <a-row>
        <a-col span="12" :class="$style.space">
          <a-form-item
            ref="addressAndPhonePrimaryPhoneNumber"
            label="Número de celular principal"
            :required="true"
            name="addressAndPhonePrimaryPhoneNumber"
          >
            <a-input
              v-maska="isBrazilian ? '+55 (##) #####-####' : '#*'"
              v-model:value="vForm.addressAndPhonePrimaryPhoneNumber"
              :readonly="readOnlyForm"
            >
            </a-input>
          </a-form-item>
        </a-col>

        <a-col span="12" :class="$style.space">
          <a-form-item
            ref="addressAndPhoneEmailAddress"
            label="Endereço de e-mail principal "
            :required="true"
            name="addressAndPhoneEmailAddress"
          >
            <a-input
              type="email"
              v-model:value="vForm.addressAndPhoneEmailAddress"
              :readonly="readOnlyForm"
            >
            </a-input>
          </a-form-item>
        </a-col>
      </a-row>
      <a-col
        span="24"
        :class="[$style.space, $style.addMore]"
        v-for="(item, amount) in vForm.addressAndPhoneSocialMediaProvider"
        :key="amount"
      >
        <a-row>
          <a-col span="12" :class="$style.space">
            <a-form-item
              label="Rede social mais usada"
              :name="['addressAndPhoneSocialMediaProvider', amount, 'value']"
              :rules="{
                required: true,
                message: 'Campo obrigatório',
                trigger: 'blur',
              }"
            >
              <a-select
                v-model:value="
                  vForm.addressAndPhoneSocialMediaProvider[amount].value
                "
                placeholder="Selecione"
                :readonly="readOnlyForm"
              >
                <a-select-option value="ASKF">ASK.FM</a-select-option>
                <a-select-option value="DUBN">DOUBAN</a-select-option>
                <a-select-option selected="selected" value="FCBK"
                  >FACEBOOK</a-select-option
                >
                <a-select-option value="FLKR">FLICKR</a-select-option>
                <a-select-option value="GOGL">GOOGLE+</a-select-option>
                <a-select-option value="INST">INSTAGRAM</a-select-option>
                <a-select-option value="LINK">LINKEDIN</a-select-option>
                <a-select-option value="MYSP">MYSPACE</a-select-option>
                <a-select-option value="PTST">PINTEREST</a-select-option>
                <a-select-option value="QZNE">QZONE (QQ)</a-select-option>
                <a-select-option value="RDDT">REDDIT</a-select-option>
                <a-select-option value="SWBO">SINA WEIBO</a-select-option>
                <a-select-option value="TWBO">TENCENT WEIBO</a-select-option>
                <a-select-option value="TUMB">TUMBLR</a-select-option>
                <a-select-option value="TWIT">TWITTER</a-select-option>
                <a-select-option value="TWOO">TWOO</a-select-option>
                <a-select-option value="VINE">VINE</a-select-option>
                <a-select-option value="VKON">VKONTAKTE (VK)</a-select-option>
                <a-select-option value="YUKU">YOUKU</a-select-option>
                <a-select-option value="YTUB">YOUTUBE</a-select-option>
                <a-select-option value="NONE">Nenhum</a-select-option>
              </a-select>
            </a-form-item>
          </a-col>
          <a-col span="12" :class="$style.space">
            <a-form-item
              ref="addressAndPhoneSocialMediaIdentifier"
              label="Identificador na rede social (nome ou arroba ou etc...)"
              :name="['addressAndPhoneSocialMediaIdentifier', amount, 'value']"
              :rules="{
                required:
                  vForm.addressAndPhoneSocialMediaProvider[amount].value &&
                  vForm.addressAndPhoneSocialMediaProvider[amount].value !=
                    'NONE',
                message: 'Campo obrigatório',
                trigger: 'blur',
              }"
            >
              <a-input
                v-model:value="
                  vForm.addressAndPhoneSocialMediaIdentifier[amount].value
                "
                :readonly="readOnlyForm"
                :disabled="
                  vForm.addressAndPhoneSocialMediaProvider[amount].value ==
                  'NONE'
                "
              >
              </a-input>
            </a-form-item>
          </a-col>
        </a-row>
      </a-col>
      <a-col span="24" :class="$style.space">
        <a-button
          type="primary"
          :class="$style.outSpace"
          size="small"
          :disabled="canNotAddMore"
          @click="addSocial()"
        >
          <template #icon>
            <PlusOutlined />
          </template>
          Adicionar mais
        </a-button>
        <a-button
          type="danger"
          size="small"
          @click="removeSocial()"
          :disabled="canNotRemoveMore"
          :class="$style.outSpace"
        >
          <template #icon>
            <MinusOutlined />
          </template>
          Remover
        </a-button>
      </a-col>
    </a-col>
  </a-row>
</template>
<script>
import { defineComponent } from "vue";
import { mapMutations, mapState, mapGetters } from "vuex";
import CountrySelect from "@/components/CountrySelect/CountrySelect";
import { getAddress, getUFFullName } from "@/utils/getCep";
import { sanitizeString } from "@/utils/utility";
export default defineComponent({
  components: { CountrySelect },
  data() {
    return {
      amountPerson: 1,
      isLoadingViaCep: false,
    };
  },
  computed: {
    ...mapState("application", ["vForm"]),
    ...mapGetters("application", ["readOnlyForm"]),
    isBrazilian() {
      return this.vForm.addressAndPhoneCountry == "BRZL";
    },
    canNotAddMore() {
      return !(this.vForm.addressAndPhoneSocialMediaProvider.length < 2);
    },
    canNotRemoveMore() {
      return !(this.vForm.addressAndPhoneSocialMediaProvider.length > 1);
    },
  },
  methods: {
    ...mapMutations("application", ["setSaveFilledForm"]),
    async getUserAddress() {
      if (this.vForm.addressAndPhoneCountry != "BRZL") {
        return;
      }
      const cep = sanitizeString(this.vForm.addressAndPhoneZipCode);
      if (cep.length == 8) {
        this.isLoadingViaCep = true;
        const address = await getAddress(cep);
        if (address) {
          this.vForm.addressAndPhoneAddressLine1 = address?.logradouro;
          this.vForm.addressAndPhoneAddressLine2 = address?.bairro;
          this.vForm.addressAndPhoneCity = address?.localidade;
          this.vForm.addressAndPhoneState = getUFFullName(address?.uf);
        }
        this.isLoadingViaCep = false;
      }
    },
    addSocial() {
      if (!this.canNotAddMore) {
        this.vForm.addressAndPhoneSocialMediaProvider.push({
          value: "",
        });
        this.vForm.addressAndPhoneSocialMediaIdentifier.push({
          value: "",
        });
      }
    },
    removeSocial() {
      if (!this.canNotRemoveMore) {
        this.vForm.addressAndPhoneSocialMediaProvider.pop();
        this.vForm.addressAndPhoneSocialMediaIdentifier.pop();
      }
    },
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
