<template>
  <a-form-item
    :ref="reference"
    :label="name"
    :name="reference"
    :rules="{
      required: isRequired,
      message: 'Estado não pode ser vazio',
      trigger: 'change',
    }"
  >
    <a-select
      show-search
      option-filter-prop="children"
      v-model:value="vForm[reference]"
      :filter-option="filterOption"
      :readonly="readOnlyForm"
      placeholder="Selecione"
    >
      <a-select-option
        v-for="(state, index) in STATES"
        :value="index"
        :key="index"
        >{{ state }}</a-select-option
      >
    </a-select>
  </a-form-item>
</template>
<script>
import { defineComponent } from "vue";
import { mapMutations, mapState, mapGetters } from "vuex";
import { STATES } from "@/utils/Constants";
export default defineComponent({
  props: {
    reference: { type: String, required: true },
    name: { type: String, required: true },
    isRequired: { type: Boolean, default: false },
  },
  data() {
    return {
      STATES,
    };
  },
  computed: {
    ...mapState("application", ["vForm"]),
    ...mapGetters("application", ["readOnlyForm"]),
  },
  methods: {
    ...mapMutations("application", ["setSaveFilledForm"]),
    filterOption(input, option) {
      return (
        option.children[0].children
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
