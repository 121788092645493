import { AVAILABLE_CONSULS } from "./Constants";
export function isArrayValid(inputTest) {
  return inputTest && Array.isArray(inputTest) && inputTest.length > 0;
}

export function isArrayOfStrings(value) {
  return (
    Array.isArray(value) && value.every((item) => typeof item === "string")
  );
}

export function sanitizeString(value) {
  return value.replace("-", "").replace(/[\s\W-]+/g, "");
}

export function isNumberValid(num) {
  if (num === undefined || num === null) {
    return false;
  }

  if (num === 0) {
    return true;
  }

  // if number, simple return true
  if (num && typeof num === "number") {
    return true;
  }
  // tries to cast string to number format
  else if (num && !isNaN(parseFloat(num))) {
    return true;
  }

  // else return false
  return false;
}

export function castNumberTo2Decimals(num) {
  if (!num) {
    return 0;
  }

  return Number(num.toFixed(2));
}

export function formatValue(value, numberOfDecimalCases = 0) {
  if (value === undefined || value === null) {
    return "-";
  }

  return Number(value.toFixed(numberOfDecimalCases)).toLocaleString("pt-br");
}

export function encodeStringToBase64(text) {
  return new Buffer(text).toString("base64");
}

export function decodeStringFromBase64(base64) {
  return new Buffer(base64, "base64").toString("ascii");
}

export function padN(number, targetLength = 2) {
  let output = number + "";
  while (output.length < targetLength) {
    output = "0" + output;
  }
  return output;
}

export function isEmailValid(email) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(email.toLocaleLowerCase());
}

export const toggleElementAll = (values) => {
  if (values) {
    if (values.length > 1) {
      if (values[0] != "all") {
        if (values.includes("all")) {
          return ["all"];
        }
      } else {
        return values.filter((e) => e != "all" && e != undefined);
      }
    }
  }
  return values;
};

export function isArrayLastElement(array, element) {
  if (array === null) return false;
  return array[array.length - 1] === element;
}

export function getConsulCity(index) {
  return AVAILABLE_CONSULS[index];
}
