<template>
  <a-row>
    <a-col span="24">
      <a-col span="24">
        <a-form-item
          ref="previousUSTravelHaveBeenUS"
          label="Você já esteve nos EUA?"
          required
          name="previousUSTravelHaveBeenUS"
        >
          <a-radio-group
            v-model:value="vForm.previousUSTravelHaveBeenUS"
            :readonly="readOnlyForm"
          >
            <a-radio-button value="Y">Sim</a-radio-button>
            <a-radio-button value="N">Não</a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-col>
      <div v-if="vForm.previousUSTravelHaveBeenUS == 'Y'">
        <a-col span="24">
          <a-row>
            <a-col span="24" :class="[$style.space]">
              <span
                >Forneça informações sobre suas últimas cinco visitas aos
                EUA:</span
              >
            </a-col>
            <a-col
              span="24"
              :class="[$style.space, $style.blueSpace, $style.outSpace]"
              v-for="(item, amount) in vForm.previousArrivalDate"
              :key="amount"
            >
              <a-col span="24">
                <a-row>
                  <a-col :span="isDesktop ? 8 : 24" :class="$style.space">
                    <a-form-item
                      ref="previousArrivalDate"
                      label="Data de chegada anteriormente"
                      :name="['previousArrivalDate', amount, 'value']"
                      :rules="{
                        required: vForm.previousUSTravelHaveBeenUS == 'Y',
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                        validator: validateDate,
                      }"
                    >
                      <a-date-picker
                        v-model:value="vForm.previousArrivalDate[amount].value"
                        type="date"
                        placeholder="Selecione"
                        style="width: 100%"
                        :disabled-date="overCurrentDate"
                        @change="
                          (val) => handlePreviousArrivalDate(val, amount)
                        "
                        :readonly="readOnlyForm"
                        :format="DEFAULT_DATE_FORMAT"
                      /> </a-form-item
                  ></a-col>
                  <a-col :span="isDesktop ? 8 : 12" :class="$style.space">
                    <a-form-item
                      ref="previousUSTravelHaveBeenUSlengthStayType"
                      label="Tempo de permanência"
                      :name="[
                        'previousUSTravelHaveBeenUSlengthStayType',
                        amount,
                        'value',
                      ]"
                      :rules="{
                        required: vForm.previousUSTravelHaveBeenUS == 'Y',
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                        validator: validateDate,
                      }"
                    >
                      <a-select
                        v-model:value="
                          vForm.previousUSTravelHaveBeenUSlengthStayType[amount]
                            .value
                        "
                        :readonly="readOnlyForm"
                        placeholder="Selecione"
                      >
                        <a-select-option value="Y">Ano(s)</a-select-option>
                        <a-select-option value="M">Mês(es)</a-select-option>
                        <a-select-option value="W">Semana(s)</a-select-option>
                        <a-select-option value="D">Dia(s)</a-select-option>
                        <a-select-option value="H"
                          >Menos de 24 horas</a-select-option
                        >
                      </a-select>
                    </a-form-item>
                  </a-col>
                  <a-col :span="isDesktop ? 8 : 12" :class="$style.space">
                    <a-form-item
                      ref="previousUSTravelHaveBeenUSlengthStay"
                      :label="amountLabel(amount)"
                      :required="
                        vForm.previousUSTravelHaveBeenUSlengthStayType[amount]
                          .value != 'H'
                      "
                      :name="[
                        'previousUSTravelHaveBeenUSlengthStay',
                        amount,
                        'value',
                      ]"
                      :rules="{
                        required:
                          vForm.previousUSTravelHaveBeenUSlengthStayType[amount]
                            .value != 'H',
                        message: 'Campo obrigatório',
                        trigger: 'blur',
                      }"
                    >
                      <a-input
                        :disabled="
                          vForm.previousUSTravelHaveBeenUSlengthStayType[amount]
                            .value == 'H'
                        "
                        v-model:value="
                          vForm.previousUSTravelHaveBeenUSlengthStay[amount]
                            .value
                        "
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>
            </a-col>
            <a-col span="24" :class="$style.space">
              <a-button
                type="primary"
                size="small"
                :disabled="canNotAddPrevious"
                @click="addPrevious()"
              >
                <template #icon>
                  <PlusOutlined />
                </template>
                Adicionar mais
              </a-button>
              <a-button
                type="danger"
                size="small"
                @click="rmPrevious()"
                :disabled="canNotRemovePrevious"
                :class="$style.outSpace"
              >
                <template #icon>
                  <MinusOutlined />
                </template>
                Remover
              </a-button>
            </a-col>
          </a-row>
          <a-col span="24">
            <a-form-item
              ref="previousUSTravelHaveBeenUSHoldDriveLicense"
              label="Você tem ou já teve carteira de motorista dos EUA?"
              required
              name="previousUSTravelHaveBeenUSHoldDriveLicense"
            >
              <a-radio-group
                v-model:value="vForm.previousUSTravelHaveBeenUSHoldDriveLicense"
                :readonly="readOnlyForm"
              >
                <a-radio-button value="Y">Sim</a-radio-button>
                <a-radio-button value="N">Não</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <div v-if="vForm.previousUSTravelHaveBeenUSHoldDriveLicense == 'Y'">
            <a-col span="24">
              <a-row>
                <a-col span="24" :class="[$style.space]">
                  <span>Providencie a seguinte informação:</span>
                </a-col>
                <a-col
                  span="24"
                  :class="[$style.space, $style.blueSpace, $style.outSpace]"
                  v-for="(item, amount) in previousTravelDriveLicense"
                  :key="amount"
                >
                  <a-col span="24">
                    <a-row>
                      <a-col :span="isDesktop ? 16 : 12" :class="$style.space">
                        <a-form-item
                          ref="previousUSTravelHaveBeenUSHoldDriveLicenseNumber"
                          label="Número da carteira de motorista"
                          :name="[
                            'previousUSTravelHaveBeenUSHoldDriveLicenseNumber',
                            amount,
                            'value',
                          ]"
                          :rules="{
                            required:
                              vForm.previousUSTravelHaveBeenUSHoldDriveLicense ==
                              'Y',
                            message: 'Campo obrigatório',
                            trigger: 'blur',
                          }"
                        >
                          <a-input
                            v-model:value="
                              previousTravelDriveLicense[amount].value
                            "
                            :readonly="readOnlyForm"
                          />
                        </a-form-item>
                      </a-col>
                      <a-col :span="isDesktop ? 8 : 12" :class="$style.space">
                        <StateUSMultiSelect
                          reference="previousUSTravelHaveBeenUSHoldDriveLicenseState"
                          :identification="amount"
                          name="Estado"
                          :isRequired="
                            vForm.previousUSTravelHaveBeenUSHoldDriveLicense ==
                            'Y'
                          "
                        />
                      </a-col>
                    </a-row>
                  </a-col>
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-button
                    type="primary"
                    size="small"
                    @click="addDL()"
                    :disabled="canNotAddDL"
                  >
                    <template #icon>
                      <PlusOutlined />
                    </template>
                    Adicionar mais
                  </a-button>
                  <a-button
                    type="danger"
                    size="small"
                    @click="rmDL()"
                    :disabled="canNotRemoveDL"
                    :class="$style.outSpace"
                  >
                    <template #icon>
                      <MinusOutlined />
                    </template>
                    Remover
                  </a-button>
                </a-col>
              </a-row>
            </a-col>
          </div>
        </a-col>
      </div>
      <a-col span="24">
        <a-form-item
          ref="previousUSTravelHaveIssuedBeenUS"
          label="Você já teve um visto emitido dos EUA?"
          required
          name="previousUSTravelHaveIssuedBeenUS"
        >
          <a-radio-group
            v-model:value="vForm.previousUSTravelHaveIssuedBeenUS"
            :readonly="readOnlyForm"
          >
            <a-radio-button value="Y">Sim</a-radio-button>
            <a-radio-button value="N">Não</a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-col>
      <div v-if="vForm.previousUSTravelHaveIssuedBeenUS == 'Y'">
        <a-col span="24">
          <a-row>
            <a-col span="24" :class="[$style.space]">
              <span>Providencie a seguinte informação:</span>
            </a-col>
            <a-col
              span="24"
              :class="[$style.space, $style.blueSpace, $style.outSpace]"
            >
              <a-row>
                <a-col :span="isDesktop ? 12 : 24" :class="$style.space">
                  <a-form-item
                    ref="previousUSVisa"
                    label="Data em que o último visto foi emitido"
                    :rules="{
                      required: vForm.previousUSTravelHaveIssuedBeenUS == 'Y',
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                      validator: validateDate,
                    }"
                    name="previousUSVisa"
                  >
                    <a-date-picker
                      v-model:value="vForm.previousUSVisa"
                      type="date"
                      placeholder="Selecione"
                      style="width: 100%"
                      @change="handlePreviousUsVisa"
                      :readonly="readOnlyForm"
                      :format="DEFAULT_DATE_FORMAT"
                    />
                  </a-form-item>
                </a-col>
                <a-col :span="isDesktop ? 12 : 24" :class="$style.space">
                  <a-form-item
                    ref="previousUSTravelHaveIssuedBeenUSDateLastVisaNumber"
                    label="Número do visto"
                    name="previousUSTravelHaveIssuedBeenUSDateLastVisaNumber"
                    :required="vForm.previousUSTravelHaveIssuedBeenUS == 'Y'"
                  >
                    <a-input
                      v-maska="['A#######', '########']"
                      v-model:value="
                        vForm.previousUSTravelHaveIssuedBeenUSDateLastVisaNumber
                      "
                      :readonly="readOnlyForm"
                    />
                  </a-form-item>
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-form-item
                    ref="previousUSTravelHaveIssuedBeenUSApplyingSameVisa"
                    label="Você está solicitando o mesmo tipo de visto?"
                    name="previousUSTravelHaveIssuedBeenUSApplyingSameVisa"
                    :required="vForm.previousUSTravelHaveIssuedBeenUS == 'Y'"
                  >
                    <a-radio-group
                      v-model:value="
                        vForm.previousUSTravelHaveIssuedBeenUSApplyingSameVisa
                      "
                      :readonly="readOnlyForm"
                    >
                      <a-radio-button value="Y">Sim</a-radio-button>
                      <a-radio-button value="N">Não</a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-form-item
                    ref="previousUSTravelHaveIssuedBeenUSApplyingSameCountry"
                    label="Você está aplicando no mesmo país ou local onde o visto acima foi emitido, e este país ou local é seu principal local de residência?"
                    name="previousUSTravelHaveIssuedBeenUSApplyingSameCountry"
                    :required="vForm.previousUSTravelHaveIssuedBeenUS == 'Y'"
                  >
                    <a-radio-group
                      v-model:value="
                        vForm.previousUSTravelHaveIssuedBeenUSApplyingSameCountry
                      "
                      :readonly="readOnlyForm"
                    >
                      <a-radio-button value="Y">Sim</a-radio-button>
                      <a-radio-button value="N">Não</a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-form-item
                    ref="previousUSTravelHaveIssuedBeenUSbeenTenPrinted"
                    label="Você já tirou a digital dos dez dedos?"
                    name="previousUSTravelHaveIssuedBeenUSbeenTenPrinted"
                    :required="vForm.previousUSTravelHaveIssuedBeenUS == 'Y'"
                  >
                    <a-radio-group
                      v-model:value="
                        vForm.previousUSTravelHaveIssuedBeenUSbeenTenPrinted
                      "
                      :readonly="readOnlyForm"
                    >
                      <a-radio-button value="Y">Sim</a-radio-button>
                      <a-radio-button value="N">Não</a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-form-item
                    ref="previousUSTravelHaveIssuedBeenUSLost"
                    label="Seu visto americano já foi perdido ou roubado?"
                    name="previousUSTravelHaveIssuedBeenUSLost"
                    :required="vForm.previousUSTravelHaveIssuedBeenUS == 'Y'"
                  >
                    <a-radio-group
                      v-model:value="vForm.previousUSTravelHaveIssuedBeenUSLost"
                      :readonly="readOnlyForm"
                    >
                      <a-radio-button value="Y">Sim</a-radio-button>
                      <a-radio-button value="N">Não</a-radio-button>
                    </a-radio-group>
                  </a-form-item>
                </a-col>
              </a-row>
              <div v-if="vForm.previousUSTravelHaveIssuedBeenUSLost == 'Y'">
                <a-col span="24" :class="[$style.whiteSpace]">
                  <a-col span="24" :class="[$style.space]">
                    <a-form-item
                      ref="previousUSTravelHaveIssuedBeenUSLostYear"
                      label="Ano"
                      name="previousUSTravelHaveIssuedBeenUSLostYear"
                      :required="vForm.previousUSTravelHaveIssuedBeenUS == 'Y'"
                    >
                      <a-input
                        v-maska="'####'"
                        v-model:value="
                          vForm.previousUSTravelHaveIssuedBeenUSLostYear
                        "
                        :readonly="readOnlyForm"
                      />
                    </a-form-item>
                  </a-col>
                  <a-col span="24" :class="$style.space">
                    <a-form-item
                      ref="previousUSTravelHaveIssuedBeenUSLostExplain"
                      label="Explicação"
                      name="previousUSTravelHaveIssuedBeenUSLostExplain"
                      :required="vForm.previousUSTravelHaveIssuedBeenUS == 'Y'"
                    >
                      <a-textarea
                        v-model:value="
                          vForm.previousUSTravelHaveIssuedBeenUSLostExplain
                        "
                        :readonly="readOnlyForm"
                        placeholder="Explique"
                        :rows="4"
                      />
                    </a-form-item> </a-col
                ></a-col>
              </div>
              <a-col span="24" :class="$style.space">
                <a-form-item
                  ref="previousUSTravelHaveIssuedBeenUSCancelled"
                  label="O seu visto para os EUA já foi cancelado ou revogado?"
                  name="previousUSTravelHaveIssuedBeenUSCancelled"
                  :required="vForm.previousUSTravelHaveIssuedBeenUS == 'Y'"
                >
                  <a-radio-group
                    v-model:value="
                      vForm.previousUSTravelHaveIssuedBeenUSCancelled
                    "
                    :readonly="readOnlyForm"
                  >
                    <a-radio-button value="Y">Sim</a-radio-button>
                    <a-radio-button value="N">Não</a-radio-button>
                  </a-radio-group>
                </a-form-item>
              </a-col>
              <div
                v-if="vForm.previousUSTravelHaveIssuedBeenUSCancelled == 'Y'"
              >
                <a-col span="24" :class="[$style.whiteSpace]">
                  <a-col span="24" :class="$style.space">
                    <a-form-item
                      ref="previousUSTravelHaveIssuedBeenUSCancelledExplain"
                      label="Explicação"
                      name="previousUSTravelHaveIssuedBeenUSCancelledExplain"
                      :required="
                        vForm.previousUSTravelHaveIssuedBeenUSCancelled == 'Y'
                      "
                    >
                      <a-textarea
                        v-model:value="
                          vForm.previousUSTravelHaveIssuedBeenUSCancelledExplain
                        "
                        :readonly="readOnlyForm"
                        placeholder="Explique"
                        :rows="4"
                      />
                    </a-form-item> </a-col
                ></a-col>
              </div>
            </a-col>
          </a-row>
        </a-col>
      </div>
      <a-row>
        <a-col span="24">
          <a-col span="24">
            <a-form-item
              ref="previousUSTravelHaveBeenRefusedAdmission"
              label="Você já teve um visto dos EUA recusado, sua admissão nos Estados Unidos foi recusada ou retirou seu pedido de admissão no porto de entrada?"
              name="previousUSTravelHaveBeenRefusedAdmission"
              :required="true"
            >
              <a-radio-group
                v-model:value="vForm.previousUSTravelHaveBeenRefusedAdmission"
                :readonly="readOnlyForm"
              >
                <a-radio-button value="Y">Sim</a-radio-button>
                <a-radio-button value="N">Não</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>
        </a-col>
      </a-row>
      <a-row>
        <a-col span="24">
          <a-col span="24">
            <a-form-item
              ref="previousUSTravelHasFiledImmigrantPetition"
              label="Alguém já entrou com uma petição de imigrante em seu nome junto aos Serviços de Cidadania e Imigração dos Estados Unidos?"
              name="previousUSTravelHasFiledImmigrantPetition"
              :required="true"
            >
              <a-radio-group
                v-model:value="vForm.previousUSTravelHasFiledImmigrantPetition"
                :readonly="readOnlyForm"
              >
                <a-radio-button value="Y">Sim</a-radio-button>
                <a-radio-button value="N">Não</a-radio-button>
              </a-radio-group>
            </a-form-item>
          </a-col>
          <div v-if="vForm.previousUSTravelHasFiledImmigrantPetition == 'Y'">
            <a-col span="24" :class="[$style.blueSpace]">
              <a-col span="24" :class="$style.space">
                <a-form-item
                  ref="previousUSTravelHasFiledImmigrantPetitionExplain"
                  label="Explicação"
                  name="previousUSTravelHasFiledImmigrantPetitionExplain"
                  :required="
                    vForm.previousUSTravelHasFiledImmigrantPetition == 'Y'
                  "
                >
                  <a-textarea
                    v-model:value="
                      vForm.previousUSTravelHasFiledImmigrantPetitionExplain
                    "
                    :readonly="readOnlyForm"
                    placeholder="Explique"
                    :rows="4"
                  />
                </a-form-item>
              </a-col>
            </a-col>
          </div>
        </a-col>
      </a-row>
    </a-col>
  </a-row>
</template>
<script>
import { defineComponent } from "vue";
import { mapMutations, mapState, mapGetters } from "vuex";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons-vue";
import StateUSMultiSelect from "@/components/StateUSSelect/StateUSMultiSelect";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_MAX_ADD_AMOUNT,
  AMOUNT_STAY,
} from "@/utils/Constants";
import { isDesktop } from "@/utils/isAgent";
import moment from "moment";
import { validateDate } from "@/utils/validation";

export default defineComponent({
  components: { PlusOutlined, StateUSMultiSelect, MinusOutlined },
  data() {
    return {
      previousTravelDriveLicense: [{ value: "" }],
      DEFAULT_DATE_FORMAT,
      isDesktop,
      validateDate,
    };
  },
  computed: {
    ...mapState("application", ["vForm"]),
    ...mapGetters("application", ["readOnlyForm"]),
    canNotAddPrevious() {
      return !(
        this.vForm.previousUSTravelHaveBeenUSlengthStay.length <
        DEFAULT_MAX_ADD_AMOUNT
      );
    },
    canNotAddDL() {
      return !(
        this.vForm.previousUSTravelHaveBeenUSHoldDriveLicenseNumber.length <
        DEFAULT_MAX_ADD_AMOUNT
      );
    },
    canNotRemovePrevious() {
      return !(this.vForm.previousUSTravelHaveBeenUSlengthStay.length > 1);
    },
    canNotRemoveDL() {
      return !(
        this.vForm.previousUSTravelHaveBeenUSHoldDriveLicenseNumber.length > 1
      );
    },
  },
  methods: {
    ...mapMutations("application", ["setSaveFilledForm"]),
    amountLabel(index) {
      return `Quantidade ${
        this.vForm.previousUSTravelHaveBeenUSlengthStayType[index].value !=
          "" &&
        this.vForm.previousUSTravelHaveBeenUSlengthStayType[index].value != "H"
          ? "de " +
            AMOUNT_STAY[
              this.vForm.previousUSTravelHaveBeenUSlengthStayType[index].value
            ]
          : ""
      }`;
    },
    overCurrentDate(current) {
      return current && current >= moment().endOf("day");
    },
    addPrevious() {
      if (!this.canNotAddPrevious) {
        this.vForm.previousUSTravelHaveBeenUSlengthStay.push({
          value: "",
        });
        this.vForm.previousUSTravelHaveBeenUSlengthStayType.push({
          value: "",
        });
        this.vForm.previousUSTravelHaveBeenUSDataArrivalDay.push({
          value: "",
        });
        this.vForm.previousUSTravelHaveBeenUSDataArrivalMonth.push({
          value: "",
        });
        this.vForm.previousUSTravelHaveBeenUSDataArrivalYear.push({
          value: "",
        });
        this.vForm.previousArrivalDate.push({ value: "" });
      }
    },
    rmPrevious() {
      if (this.vForm.previousUSTravelHaveBeenUSlengthStay.length > 1) {
        this.vForm.previousUSTravelHaveBeenUSlengthStay.pop();
        this.vForm.previousUSTravelHaveBeenUSlengthStayType.pop();
        this.vForm.previousUSTravelHaveBeenUSDataArrivalDay.pop();
        this.vForm.previousUSTravelHaveBeenUSDataArrivalMonth.pop();
        this.vForm.previousUSTravelHaveBeenUSDataArrivalYear.pop();
        this.vForm.previousArrivalDate.pop();
      }
    },
    addDL() {
      if (!this.canNotAddDL) {
        this.vForm.previousUSTravelHaveBeenUSHoldDriveLicenseNumber.push({
          value: "",
        });
        this.vForm.previousUSTravelHaveBeenUSHoldDriveLicenseState.push({
          value: "",
        });
      }
    },
    rmDL() {
      if (
        this.vForm.previousUSTravelHaveBeenUSHoldDriveLicenseNumber.length > 1
      ) {
        this.vForm.previousUSTravelHaveBeenUSHoldDriveLicenseNumber.pop();
        this.vForm.previousUSTravelHaveBeenUSHoldDriveLicenseState.pop();
      }
    },
    handlePreviousUsVisa(val) {
      var check = moment(val, "YYYY/MM/DD");

      var month = check.format("MM");
      var day = check.format("DD");
      var year = check.format("YYYY");

      this.vForm.previousUSTravelHaveIssuedBeenUSDateLastVisaWasIssuedDay = day;
      this.vForm.previousUSTravelHaveIssuedBeenUSDateLastVisaWasIssuedMonth =
        month;
      this.vForm.previousUSTravelHaveIssuedBeenUSDateLastVisaWasIssuedYear =
        year;
    },
    handlePreviousArrivalDate(val, index) {
      var check = moment(val, "YYYY/MM/DD");

      var month = check.format("MM");
      var day = check.format("DD");
      var year = check.format("YYYY");

      this.vForm.previousUSTravelHaveBeenUSDataArrivalDay[index].value = day;
      this.vForm.previousUSTravelHaveBeenUSDataArrivalMonth[index].value =
        month;
      this.vForm.previousUSTravelHaveBeenUSDataArrivalYear[index].value = year;
    },
  },
  mounted() {
    // this.vForm.previousArrivalDate = this.vForm.previousArrivalDate?.map(
    //   (e) => {
    //     if (e && e?.value != "") {
    //       return { value: moment(e.value) };
    //     }
    //     return { value: "" };
    //   }
    // );
    // this.vForm.previousUSVisa = moment(this.vForm.previousUSVisa);
    this.previousTravelDriveLicense =
      this.vForm.previousUSTravelHaveBeenUSHoldDriveLicenseNumber;
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
