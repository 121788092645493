<template>
  <a-row>
    <a-col span="24">
      <a-collapse v-model:activeKey="activeKey">
        <template #expandIcon="{ isActive }">
          <RightOutlined :rotate="isActive ? 90 : 0" />
        </template>
        <a-collapse-panel
          showArrow
          key="1"
          header="Informações do passaporte"
          :class="$style.customStyle"
        >
          <a-col span="24">
            <a-row>
              <a-col span="24" :class="$style.space">
                <a-form-item
                  label="Tipo de passaporte"
                  name="passportDocumentType"
                  required
                >
                  <a-select
                    v-model:value="vForm.passportDocumentType"
                    :readonly="readOnlyForm"
                    placeholder="Selecione"
                  >
                    <a-select-option value="R">Regular</a-select-option>
                    <a-select-option value="O">Oficial</a-select-option>
                    <a-select-option value="D">Diplomático</a-select-option>
                    <a-select-option value="L">Laissez-Passer</a-select-option>
                    <a-select-option value="T">Outro</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>

              <a-col span="24">
                <div v-if="vForm.passportDocumentType == 'T'">
                  <a-col span="24" :class="[$style.space, $style.addMore]">
                    <a-form-item
                      label="Explicação"
                      name="passportDocumentTypeExplain"
                      :required="vForm.passportDocumentType == 'T'"
                    >
                      <a-textarea
                        v-model:value="vForm.passportDocumentTypeExplain"
                        placeholder="Explique"
                        :readonly="readOnlyForm"
                        :rows="4"
                      />
                    </a-form-item>
                  </a-col>
                </div>
              </a-col>

              <a-col span="24" :class="$style.space">
                <CountrySelect
                  reference="passportAuthorityDocumentCountry"
                  name="País/Autoridade que emitiu passaporte/documento de viagem"
                  :isRequired="true"
                />
              </a-col>
              <a-col span="24">
                <a-row>
                  <a-col span="24" :class="$style.space">
                    <a-form-item
                      ref="passportDocumentNumber"
                      label="Número do passaporte"
                      name="passportDocumentNumber"
                      :disabled="!vForm.passportAuthorityDocumentCountry"
                      required
                    >
                      <a-input
                        v-model:value="vForm.passportDocumentNumber"
                        v-maska="isBrazilianPassport ? 'AA######' : undefined"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <a-row>
              <a-col span="24" :class="[$style.space, $style.addMore]">
                <a-row>
                  <a-col :span="isDesktop ? 12 : 24" :class="$style.space">
                    <a-form-item
                      ref="passportAuthorityDocumentCity"
                      label="Cidade"
                      name="passportAuthorityDocumentCity"
                      required
                    >
                      <a-input
                        v-model:value="vForm.passportAuthorityDocumentCity"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col :span="isDesktop ? 12 : 24" :class="$style.space">
                    <a-form-item
                      ref="passportAuthorityDocumentState"
                      label="Estado (se mostrar no passaporte)"
                      name="passportAuthorityDocumentState"
                    >
                      <a-input
                        v-model:value="vForm.passportAuthorityDocumentState"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>

                  <a-col span="24" :class="$style.space">
                    <CountrySelect
                      reference="passportAuthorityDocumentRegion"
                      name="País"
                      :isRequired="true"
                    />
                  </a-col>
                </a-row>
              </a-col>
            </a-row>
            <a-row>
              <a-col :span="isDesktop ? 12 : 24" :class="$style.space">
                <a-form-item
                  ref="issuanceDatePassport"
                  label="Data de emissão"
                  :rules="{
                    required: true,
                    trigger: 'blur',
                    validator: (rule, value) =>
                      validateDateEarlyThen(rule, value, 50),
                  }"
                  name="issuanceDatePassport"
                >
                  <a-date-picker
                    v-model:value="vForm.issuanceDatePassport"
                    type="date"
                    placeholder="Selecione"
                    style="width: 100%"
                    :readonly="readOnlyForm"
                    :disabled-date="aboveCurrentDate"
                    @change="handleInssuance"
                    :format="DEFAULT_DATE_FORMAT"
                  />
                </a-form-item>
              </a-col>

              <a-col :span="isDesktop ? 12 : 24" :class="$style.space">
                <a-form-item
                  ref="expirationDatePassport"
                  label="Data de expiração"
                  :rules="{
                    required: true,
                    message: 'Campo obrigatório',
                    trigger: 'blur',
                    validator: validateDate,
                  }"
                  name="expirationDatePassport"
                >
                  <a-date-picker
                    v-model:value="vForm.expirationDatePassport"
                    type="date"
                    placeholder="Selecione"
                    style="width: 100%"
                    :disabled-date="overCurrentDate"
                    :readonly="readOnlyForm"
                    @change="handleExpirationDate"
                    :format="DEFAULT_DATE_FORMAT"
                  />
                </a-form-item>
              </a-col>
            </a-row>
            <a-col span="24" :class="$style.space">
              <a-form-item
                ref="passportHavePassportStolen"
                label="Você já perdeu ou já roubaram o seu passaporte"
                name="passportHavePassportStolen"
                :required="true"
              >
                <a-radio-group
                  v-model:value="vForm.passportHavePassportStolen"
                  :readonly="readOnlyForm"
                >
                  <a-radio-button value="Y">Sim</a-radio-button>
                  <a-radio-button value="N">Não</a-radio-button>
                </a-radio-group>
              </a-form-item>
            </a-col>
            <div v-if="vForm.passportHavePassportStolen == 'Y'">
              <a-col
                span="24"
                :class="[$style.space, $style.addMore]"
                v-for="(
                  item, amount
                ) in vForm.passportHavePassportStolenDocumentNumber"
                :key="amount"
              >
                <a-col span="24" :class="$style.space">
                  <a-form-item
                    ref="passportHavePassportStolenDocumentNumber"
                    label="Número do passaporte (Caso não saiba considere AA000000)"
                    :name="[
                      'passportHavePassportStolenDocumentNumber',
                      amount,
                      'value',
                    ]"
                    :required="hasStolen"
                    :rules="{
                      required: hasStolen,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                    }"
                  >
                    <a-input
                      v-model:value="
                        vForm.passportHavePassportStolenDocumentNumber[amount]
                          .value
                      "
                      :readonly="readOnlyForm"
                      v-maska="isBrazilianPassport ? 'AA######' : undefined"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col span="24" :class="$style.space">
                  <MultiCountrySelect
                    reference="passportHavePassportStolenCountry"
                    name="País"
                    :identification="amount"
                    :isRequired="hasStolen"
                  />
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-form-item
                    ref="passportHavePassportStolenExplain"
                    label="Explique"
                    :name="[
                      'passportHavePassportStolenExplain',
                      amount,
                      'value',
                    ]"
                    :required="hasStolen"
                    :rules="{
                      required: hasStolen,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                    }"
                  >
                    <a-textarea
                      v-model:value="
                        vForm.passportHavePassportStolenExplain[amount].value
                      "
                      placeholder="Explique"
                      :readonly="readOnlyForm"
                      :rows="4"
                    />
                  </a-form-item>
                </a-col>
              </a-col>
              <a-col span="24" :class="$style.space">
                <a-button
                  type="primary"
                  :class="$style.outSpace"
                  size="small"
                  :disabled="canNotAddMore"
                  @click="addStolen()"
                >
                  <template #icon>
                    <PlusOutlined />
                  </template>
                  Adicionar mais
                </a-button>
                <a-button
                  type="danger"
                  size="small"
                  @click="removeStolen()"
                  :disabled="canNotRemoveMore"
                  :class="$style.outSpace"
                >
                  <template #icon>
                    <MinusOutlined />
                  </template>
                  Remover
                </a-button>
              </a-col>
            </div>
          </a-col>
        </a-collapse-panel>
      </a-collapse>
    </a-col>
  </a-row>
</template>
<script>
import { defineComponent, ref } from "vue";
import { RightOutlined } from "@ant-design/icons-vue";
import { mapMutations, mapState, mapGetters } from "vuex";
import { DEFAULT_DATE_FORMAT, DEFAULT_MAX_ADD_AMOUNT } from "@/utils/Constants";
import CountrySelect from "@/components/CountrySelect/CountrySelect";
import MultiCountrySelect from "@/components/CountrySelect/MultiCountrySelect";
import { isDesktop } from "@/utils/isAgent";
import { validateDate, validateDateEarlyThen } from "@/utils/validation";
import moment from "moment";

export default defineComponent({
  setup() {
    const activeKey = ref(["1"]);
    return {
      activeKey,
      DEFAULT_DATE_FORMAT,
      validateDate,
      validateDateEarlyThen,
    };
  },
  data() {
    return {
      amountStolen: 1,
      isDesktop,
    };
  },
  components: { MultiCountrySelect, CountrySelect, RightOutlined },
  computed: {
    ...mapGetters("application", ["readOnlyForm"]),
    ...mapState("application", ["vForm"]),
    isBrazilianPassport() {
      return (
        this.vForm.passportAuthorityDocumentCountry &&
        this.vForm.passportAuthorityDocumentCountry == "BRZL"
      );
    },
    hasStolen() {
      return this.vForm.passportHavePassportStolen == "Y";
    },
    canNotAddMore() {
      return !(
        this.vForm.passportHavePassportStolenExplain.length <
        DEFAULT_MAX_ADD_AMOUNT
      );
    },
    canNotRemoveMore() {
      return !(this.vForm.passportHavePassportStolenExplain.length > 1);
    },
  },
  methods: {
    ...mapMutations("application", ["setSaveFilledForm"]),
    aboveCurrentDate(current) {
      return current && current > moment().endOf("day");
    },
    overCurrentDate(current) {
      return current && current <= moment().endOf("day");
    },
    addStolen() {
      if (!this.canNotAddMore) {
        this.vForm.passportHavePassportStolenExplain.push({ value: "" });
        this.vForm.passportHavePassportStolenCountry.push({ value: "" });
        this.vForm.passportHavePassportStolenDocumentNumber.push({ value: "" });
      }
    },
    removeStolen() {
      if (!this.canNotRemoveMore) {
        this.vForm.passportHavePassportStolenExplain.pop();
        this.vForm.passportHavePassportStolenCountry.pop();
        this.vForm.passportHavePassportStolenDocumentNumber.pop();
      }
    },
    handleExpirationDate(val) {
      var check = moment(val, "YYYY/MM/DD");

      var month = check.format("MM");
      var day = check.format("DD");
      var year = check.format("YYYY");

      this.vForm.passportExpirationDateDay = day;
      this.vForm.passportExpirationDateMonth = month;
      this.vForm.passportExpirationDateYear = year;
    },
    handleInssuance(val) {
      var check = moment(val, "YYYY/MM/DD");

      var month = check.format("MM");
      var day = check.format("DD");
      var year = check.format("YYYY");

      this.vForm.passportIssuanceDateDay = day;
      this.vForm.passportIssuanceDateMonth = month;
      this.vForm.passportIssuanceDateYear = year;
    },
  },
  mounted() {
    // this.vForm.issuanceDatePassport = moment(
    //   this.vForm.issuanceDatePassport
    // );
    // this.vForm.expirationDatePassport = moment(
    //   this.vForm.expirationDatePassport
    // );
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
