<template>
  <Process />
</template>
<script>
import { defineComponent } from "vue";
import Process from "@/containers/Process/Process";
export default defineComponent({
  components: {
    Process,
  },
  setup() {
    return {};
  },
});
</script>
