<template>
  <a-col span="24">
    <a-form-item
      ref="travelCompanionsHasPersonTraveling"
      label="Há outras pessoas viajando com você?"
      required
      name="travelCompanionsHasPersonTraveling"
    >
      <a-radio-group
        v-model:value="vForm.travelCompanionsHasPersonTraveling"
        :readonly="readOnlyForm"
      >
        <a-radio-button value="Y">Sim</a-radio-button>
        <a-radio-button value="N">Não</a-radio-button>
      </a-radio-group>
    </a-form-item>
  </a-col>
  <div v-if="vForm.travelCompanionsHasPersonTraveling == 'Y'">
    <a-row>
      <a-col span="24">
        <a-form-item
          ref="travelCompanionsIsInGroup"
          label="Você está viajando como parte de um grupo ou organização?"
          name="travelCompanionsIsInGroup"
          :required="vForm.travelCompanionsHasPersonTraveling == 'Y'"
        >
          <a-radio-group
            v-model:value="vForm.travelCompanionsIsInGroup"
            :readonly="readOnlyForm"
          >
            <a-radio-button value="Y">Sim</a-radio-button>
            <a-radio-button value="N">Não</a-radio-button>
          </a-radio-group>
        </a-form-item> </a-col
      ><a-col span="24">
        <div v-if="vForm.travelCompanionsIsInGroup == 'Y'">
          <a-col span="24">
            <a-row>
              <a-col span="24" :class="[$style.space]">
                <span
                  >Insira o nome do grupo com o qual você está viajando</span
                >
              </a-col>
              <a-col
                span="24"
                :class="[$style.space, $style.addMore, $style.outSpace]"
              >
                <a-form-item
                  ref="travelCompanionsYesGroupName"
                  label="Nome"
                  name="travelCompanionsYesGroupName"
                  :required="vForm.travelCompanionsHasPersonTraveling == 'Y'"
                >
                  <a-input
                    v-model:value="vForm.travelCompanionsYesGroupName"
                    :readonly="readOnlyForm"
                  />
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
        </div>
      </a-col>
      <div v-if="vForm.travelCompanionsIsInGroup == 'N'">
        <a-row>
          <a-col span="24" :class="[$style.space]">
            <span>Forneça os nomes de pessoas viajando com você:</span>
          </a-col>
          <a-col
            span="24"
            :class="[$style.space, $style.addMore, $style.outSpace]"
            v-for="(item, amount) in vForm.travelCompanionsNoPersonGiven"
            :key="amount"
          >
            <a-row>
              <a-col :span="isDesktop ? 8 : 12" :class="[$style.space]">
                <a-form-item
                  ref="travelCompanionsNoPersonGiven"
                  label="Nome"
                  :name="['travelCompanionsNoPersonGiven', amount, 'value']"
                  :rules="{
                    required: vForm.travelCompanionsIsInGroup == 'N',
                    message: 'Campo obrigatório',
                    trigger: 'blur',
                    type: 'string',
                  }"
                >
                  <a-input
                    v-model:value="
                      vForm.travelCompanionsNoPersonGiven[amount].value
                    "
                    :readonly="readOnlyForm"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="isDesktop ? 8 : 12" :class="[$style.space]">
                <a-form-item
                  ref="travelCompanionsNoPersonSurname"
                  label="Sobrenome"
                  :name="['travelCompanionsNoPersonSurname', amount, 'value']"
                  :rules="{
                    required: vForm.travelCompanionsIsInGroup == 'N',
                    message: 'Campo obrigatório',
                    trigger: 'blur',
                    type: 'string',
                  }"
                >
                  <a-input
                    v-model:value="
                      vForm.travelCompanionsNoPersonSurname[amount].value
                    "
                    :readonly="readOnlyForm"
                  />
                </a-form-item>
              </a-col>
              <a-col :span="isDesktop ? 8 : 24" :class="$style.space">
                <a-form-item
                  ref="travelCompanionsNoPersonRelation"
                  label="Relacionamento com você"
                  :name="['travelCompanionsNoPersonRelation', amount, 'value']"
                  :rules="{
                    required: vForm.travelCompanionsIsInGroup == 'N',
                    message: 'Campo obrigatório',
                    trigger: 'blur',
                    type: 'string',
                  }"
                >
                  <a-select
                    v-model:value="
                      vForm.travelCompanionsNoPersonRelation[amount].value
                    "
                    :readonly="readOnlyForm"
                    placeholder="Selecione"
                  >
                    <a-select-option value="C">Filho</a-select-option>
                    <a-select-option value="P">Pais</a-select-option>
                    <a-select-option value="S">Cônjuge</a-select-option>
                    <a-select-option value="R">Outro Parente</a-select-option>
                    <a-select-option value="F">Amigo</a-select-option>
                    <a-select-option value="O">Outro</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </a-col>
          <a-col span="24" :class="$style.space">
            <a-button
              type="primary"
              size="small"
              @click="addPerson()"
              :disabled="canNotAddPerson"
              :class="$style.outSpace"
            >
              <template #icon>
                <PlusOutlined />
              </template>
              Adicionar mais
            </a-button>
            <a-button
              type="danger"
              size="small"
              @click="rmPerson()"
              :disabled="canNotRemovePerson"
              :class="$style.outSpace"
            >
              <template #icon>
                <MinusOutlined />
              </template>
              Remover
            </a-button>
          </a-col>
        </a-row>
      </div>
    </a-row>
  </div>
</template>
<script>
import { defineComponent } from "vue";
import { mapMutations, mapState, mapGetters } from "vuex";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons-vue";
import { DEFAULT_MAX_ADD_AMOUNT } from "@/utils/Constants";
import { isDesktop } from "@/utils/isAgent";

export default defineComponent({
  components: { PlusOutlined, MinusOutlined },
  data() {
    return {
      amountPerson: 1,
      isDesktop,
    };
  },
  computed: {
    ...mapState("application", ["vForm"]),
    ...mapGetters("application", ["readOnlyForm"]),
    canNotAddPerson() {
      return !(
        this.vForm.travelCompanionsNoPersonGiven.length < DEFAULT_MAX_ADD_AMOUNT
      );
    },
    canNotRemovePerson() {
      return !(this.vForm.travelCompanionsNoPersonGiven.length > 1);
    },
  },
  methods: {
    ...mapMutations("application", ["setSaveFilledForm"]),
    addPerson() {
      if (!this.canNotAddPerson) {
        this.vForm.travelCompanionsNoPersonGiven.push({ value: "" });
        this.vForm.travelCompanionsNoPersonSurname.push({ value: "" });
        this.vForm.travelCompanionsNoPersonRelation.push({ value: "" });
      }
    },
    rmPerson() {
      if (this.vForm.travelCompanionsNoPersonGiven.length > 1) {
        this.vForm.travelCompanionsNoPersonGiven.pop();
        this.vForm.travelCompanionsNoPersonSurname.pop();
        this.vForm.travelCompanionsNoPersonRelation.pop();
      }
    },
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
