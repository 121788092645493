<template>
  <a-col span="24">
    <a-form-item
      v-if="hasLessThan60YearsOldAndOver14"
      ref="travelHasMadePlans"
      label="Você fez planos de viagem específicos?"
      name="travelHasMadePlans"
      required
    >
      <a-radio-group v-model:value="vForm.travelHasMadePlans" :readonly="readOnlyForm">
        <a-radio-button value="Y">Sim</a-radio-button>
        <a-radio-button value="N">Não</a-radio-button>
      </a-radio-group>
    </a-form-item>
    <a-row v-if="travelSuggestions?.items?.length">
      <a-col span="24">
        <div :class="[$style.space, $style.addMore, $style.outSpace]">
          <a-row style="margin-bottom: 2px"
            >Temos algumas recomendações para você (Clique para escolher caso tenha
            interesse)</a-row
          >
          <Suggestions :suggestions="travelSuggestions" />
        </div>
      </a-col>
    </a-row>
    <div v-if="hasLessThan60YearsOldAndOver14 && vForm.travelHasMadePlans == 'Y'">
      <a-row>
        <a-col :span="isDesktop ? 12 : 24">
          <div :class="[$style.space, $style.addMore, $style.outSpace]">
            <a-form-item
              ref="arrivalDate"
              label="Data de Chegada nos Estados Unidos"
              :rules="{
                required: vForm.travelHasMadePlans == 'Y',
                message: 'Campo obrigatório',
                trigger: 'blur',
                validator: validateDate,
              }"
              name="arrivalDate"
            >
              <a-date-picker
                v-model:value="vForm.arrivalDate"
                type="date"
                placeholder="Selecione"
                style="width: 100%"
                :disabled-date="overCurrentDate"
                @change="handleArrivalDate"
                :readonly="readOnlyForm"
                :format="DEFAULT_DATE_FORMAT"
              />
            </a-form-item>

            <a-form-item
              ref="travelYesArrivalFlight"
              label="Voo de chegada (se souber)"
              name="travelYesArrivalFlight"
            >
              <a-input
                v-model:value="vForm.travelYesArrivalFlight"
                :readonly="readOnlyForm"
              >
                <template #suffix>
                  <a-tooltip :title="flightArrivalTooltip">
                    <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
                  </a-tooltip>
                </template>
              </a-input>
            </a-form-item>
            <a-form-item
              ref="travelYesArrivalCity"
              label="Cidade de chegada no Estados Unidos"
              name="travelYesArrivalCity"
              :rules="{
                required: vForm.travelHasMadePlans == 'Y',
                message: 'Campo obrigatório',
                trigger: 'change',
              }"
            >
              <a-input
                v-model:value="vForm.travelYesArrivalCity"
                :readonly="readOnlyForm"
              >
                <template #suffix>
                  <a-tooltip :title="cityArrivalTooltip">
                    <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
                  </a-tooltip>
                </template>
              </a-input>
            </a-form-item>
          </div>
        </a-col>
        <a-col :span="isDesktop ? 12 : 24">
          <div :class="[$style.space, $style.addMore, $style.outSpace]">
            <a-form-item
              ref="departureDate"
              label="Data de Partida dos Estados Unidos."
              name="departureDate"
              :rules="{
                required: vForm.travelHasMadePlans == 'Y',
                message: 'Campo obrigatório',
                trigger: 'blur',
                validator: validateDate,
              }"
            >
              <a-date-picker
                v-model:value="vForm.departureDate"
                type="date"
                placeholder="Selecione"
                style="width: 100%"
                :disabled-date="afterArrivedDate"
                @change="handleDepartureDate"
                :format="DEFAULT_DATE_FORMAT"
                :readonly="readOnlyForm"
              />
            </a-form-item>

            <a-form-item
              ref="travelYesDepartureFlight"
              label="Voo de origem (se souber)"
              name="travelYesDepartureFlight"
            >
              <a-input
                v-model:value="vForm.travelYesDepartureFlight"
                :readonly="readOnlyForm"
              >
                <template #suffix>
                  <a-tooltip :title="flightDepartureTooltip">
                    <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
                  </a-tooltip>
                </template>
              </a-input>
            </a-form-item>
            <a-form-item
              ref="travelYesDepartureCity"
              label="Cidade de origem no Estados unidos"
              name="travelYesDepartureCity"
              :rules="{
                required: vForm.travelHasMadePlans == 'Y',
                message: 'Campo obrigatório',
                trigger: 'change',
              }"
            >
              <a-input
                v-model:value="vForm.travelYesDepartureCity"
                :readonly="readOnlyForm"
              >
                <template #suffix>
                  <a-tooltip :title="cityDepartureTooltip">
                    <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
                  </a-tooltip>
                </template>
              </a-input>
            </a-form-item>
          </div>
        </a-col>
      </a-row>
    </div>
    <div v-if="hasLessThan60YearsOldAndOver14 && vForm.travelHasMadePlans == 'Y'">
      <a-row>
        <a-col span="24" :class="[$style.space]">
          <span>Forneça os locais que você planeja visitar nos EUA:</span>
        </a-col>
        <a-col
          span="24"
          :class="[$style.space, $style.addMore, $style.outSpace]"
          v-for="(item, amount) in vForm.travelYesLocationsVisit"
          :key="amount"
        >
          <a-form-item
            ref="travelYesLocationsVisit"
            label="Local (apenas um local de cada vez)"
            :name="['travelYesLocationsVisit', amount, 'value']"
            :rules="{
              required: vForm.travelHasMadePlans == 'Y',
              message: 'Campo obrigatório',
              trigger: 'blur',
            }"
          >
            <a-input
              v-model:value="vForm.travelYesLocationsVisit[amount].value"
              :readonly="readOnlyForm"
            />
          </a-form-item>
        </a-col>
        <a-col span="24" :class="$style.space">
          <a-button
            type="primary"
            :class="$style.outSpace"
            size="small"
            :disabled="canNotAddTravel"
            @click="addTravel()"
          >
            <template #icon>
              <PlusOutlined />
            </template>
            Adicionar mais
          </a-button>
          <a-button
            type="danger"
            size="small"
            @click="rmTravel()"
            :disabled="canNotRemoveTravel"
            :class="$style.outSpace"
          >
            <template #icon>
              <MinusOutlined />
            </template>
            Remover
          </a-button>
        </a-col>
      </a-row>
    </div>
  </a-col>

  <div
    v-if="vForm.travelHasMadePlans == 'N' || hasOverThan60YearsOldAndLessThan14YearsOld"
  >
    <a-row>
      <a-col span="24">
        <div :class="[$style.space, $style.addMore, $style.outSpace]">
          <a-row>
            <a-col span="24" :class="$style.space">
              <a-form-item
                ref="intendedDate"
                label="Data Prevista de Chegada no EUA"
                :rules="{
                  required: vForm.travelHasMadePlans == 'N',
                  message: 'Campo obrigatório',
                  trigger: 'blur',
                  validator: validateDate,
                }"
                name="intendedDate"
              >
                <a-date-picker
                  v-model:value="vForm.intendedDate"
                  type="date"
                  placeholder="Escolher.."
                  style="width: 100%"
                  :disabled-date="overCurrentDate"
                  @change="handleIntendedDate"
                  :readonly="readOnlyForm"
                  :format="DEFAULT_DATE_FORMAT"
                />
              </a-form-item>
            </a-col>
            <a-col span="24">
              <a-row>
                <a-col span="12" :class="$style.space">
                  <a-form-item
                    ref="travelNoIntentLengthStayType"
                    label="Tempo previsto de permanência nos EUA"
                    :required="vForm.travelHasMadePlans == 'N'"
                    name="travelNoIntentLengthStayType"
                  >
                    <a-select
                      v-model:value="vForm.travelNoIntentLengthStayType"
                      :readonly="readOnlyForm"
                      placeholder="Selecione"
                    >
                      <a-select-option value="Y">Ano(s)</a-select-option>
                      <a-select-option value="M">Mês(es)</a-select-option>
                      <a-select-option value="W">Semana(s)</a-select-option>
                      <a-select-option value="D">Dia(s)</a-select-option>
                      <a-select-option value="H">Menos de 24 horas</a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col span="12" :class="$style.space">
                  <a-form-item
                    ref="travelNoIntentLengthStay"
                    :label="amountLabel"
                    :required="true"
                    name="travelNoIntentLengthStay"
                  >
                    <a-input
                      type="number"
                      :disabled="vForm.travelNoIntentLengthStayType == 'H'"
                      v-model:value="vForm.travelNoIntentLengthStay"
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
  <div
    v-if="
      (vForm.travelHasMadePlans && vForm.travelNoIntentLengthStayType != 'H') ||
      hasOverThan60YearsOldAndLessThan14YearsOld
    "
  >
    <a-row>
      <a-col span="24" :class="[$style.space]">
        <span>Local de hospedagem:</span>
      </a-col>
      <a-col span="24">
        <div :class="[$style.space, $style.addMore, $style.outSpace]">
          <a-row>
            <a-col span="24">
              <a-row>
                <a-col span="24" :class="$style.space">
                  <a-form-item
                    ref="travelNoIntentOganizationName"
                    label="Nome da hospedagem"
                    name="travelNoIntentOganizationName"
                    :rules="{
                      required: true,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                    }"
                  >
                    <a-input
                      v-model:value="vForm.travelNoIntentOganizationName"
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-form-item
                    ref="travelNoIntentAddressStayZipCode"
                    label="CEP/Zip Code do Estados Unidos"
                    :required="true"
                    name="travelNoIntentAddressStayZipCode"
                  >
                    <a-input
                      :disabled="isLoadingCepIntent"
                      :loading="isLoadingCepIntent"
                      v-maska="'#####'"
                      v-model:value="vForm.travelNoIntentAddressStayZipCode"
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col span="12" :class="$style.space">
                  <a-form-item
                    ref="travelNoIntentAddressStayLine1"
                    label="Endereço"
                    name="travelNoIntentAddressStayLine1"
                    :required="true"
                  >
                    <a-input
                      :disabled="isLoadingCepIntent"
                      v-model:value="vForm.travelNoIntentAddressStayLine1"
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col span="12" :class="$style.space">
                  <a-form-item
                    ref="travelNoIntentAddressStayLine2"
                    label="Complemento"
                    name="travelNoIntentAddressStayLine2"
                  >
                    <a-input
                      :disabled="isLoadingCepIntent"
                      v-model:value="vForm.travelNoIntentAddressStayLine2"
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
              </a-row>
              <a-row>
                <a-col span="12" :class="$style.space">
                  <a-form-item
                    ref="travelNoIntentAddressStayCity"
                    label="Cidade"
                    name="travelNoIntentAddressStayCity"
                    :required="true"
                  >
                    <a-input
                      :disabled="isLoadingCepIntent"
                      v-model:value="vForm.travelNoIntentAddressStayCity"
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
                <a-col span="12" :class="$style.space">
                  <StateUSSelect
                    reference="travelNoIntentAddressStayState"
                    name="Estado"
                    :isRequired="true"
                  />
                </a-col>
                <a-col span="24" :class="$style.space">
                  <a-form-item
                    ref="travelNoIntentAddressStayPhoneNumber"
                    label="Número de Telefone"
                    name="travelNoIntentAddressStayPhoneNumber"
                    :rules="{
                      required: true,
                      message: 'Campo obrigatório',
                      trigger: 'blur',
                    }"
                  >
                    <a-input
                      v-maska="['+1 (###) ##-##-##', '+1 (###) ###-##-##']"
                      v-model:value="vForm.travelNoIntentAddressStayPhoneNumber"
                      :readonly="readOnlyForm"
                    >
                    </a-input>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
          </a-row>
        </div>
      </a-col>
    </a-row>
  </div>
  <a-col span="24">
    <a-form-item
      ref="travelWhoPaying"
      label="Pessoa/Entidade pagando pela sua viagem"
      name="travelWhoPaying"
      required
    >
      <a-select
        placeholder="Selecione"
        v-model:value="vForm.travelWhoPaying"
        :readonly="readOnlyForm"
      >
        <a-select-option value="S">Eu mesmo</a-select-option>
        <a-select-option value="O">Outra pessoa</a-select-option>
        <a-select-option value="P">Meu empregador</a-select-option>
        <a-select-option value="U">Empregador no EUA</a-select-option>
        <a-select-option value="C">Outra empresa/Organização</a-select-option>
      </a-select>
    </a-form-item>
    <div v-if="vForm.travelWhoPaying == 'O'">
      <a-row>
        <a-col span="24">
          <div :class="[$style.space, $style.addMore, $style.outSpace]">
            <a-row>
              <a-col span="12" :class="$style.space">
                <a-form-item
                  ref="travelWhoPayingPersonGiven"
                  label="Nome"
                  :required="vForm.travelWhoPaying == 'O'"
                  name="travelWhoPayingPersonGiven"
                >
                  <a-input
                    v-model:value="vForm.travelWhoPayingPersonGiven"
                    :readonly="readOnlyForm"
                  >
                    <template #suffix>
                      <a-tooltip :title="PASSPORT_WITHOUT_NAME">
                        <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
                      </a-tooltip>
                    </template>
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col span="12" :class="$style.space">
                <a-form-item
                  ref="travelWhoPayingPersonSurname"
                  label="Sobrenome"
                  name="travelWhoPayingPersonSurname"
                  :required="vForm.travelWhoPaying == 'O'"
                >
                  <a-input
                    v-model:value="vForm.travelWhoPayingPersonSurname"
                    :readonly="readOnlyForm"
                  >
                    <template #suffix>
                      <a-tooltip :title="SURNAMES_LIKE_PASSPORT">
                        <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
                      </a-tooltip>
                    </template>
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col :span="isDesktop ? 8 : 12" :class="$style.space">
                <a-form-item
                  ref="travelWhoPayingPersonTelephone"
                  label="Telefone"
                  :required="vForm.travelWhoPaying == 'O'"
                  name="travelWhoPayingPersonTelephone"
                >
                  <a-input
                    v-model:value="vForm.travelWhoPayingPersonTelephone"
                    :readonly="readOnlyForm"
                  >
                    <template #suffix>
                      <a-tooltip :title="PASSPORT_WITHOUT_NAME">
                        <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
                      </a-tooltip>
                    </template>
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col :span="isDesktop ? 8 : 12" :class="$style.space">
                <a-form-item
                  ref="travelWhoPayingPersonEmail"
                  label="E-mail"
                  :required="vForm.travelWhoPaying == 'O'"
                  name="travelWhoPayingPersonEmail"
                >
                  <a-input
                    type="email"
                    v-model:value="vForm.travelWhoPayingPersonEmail"
                    :readonly="readOnlyForm"
                  >
                    <template #suffix>
                      <a-tooltip :title="SURNAMES_LIKE_PASSPORT">
                        <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
                      </a-tooltip>
                    </template>
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col :span="isDesktop ? 8 : 24" :class="$style.space">
                <a-form-item
                  ref="travelWhoPayingPersonRelation"
                  label="Relacionamento com você"
                  name="travelWhoPayingPersonRelation"
                  :required="vForm.travelWhoPaying == 'O'"
                >
                  <a-select
                    v-model:value="vForm.travelWhoPayingPersonRelation"
                    :readonly="readOnlyForm"
                    placeholder="Selecione"
                  >
                    <a-select-option value="C">Filho</a-select-option>
                    <a-select-option value="P">Pais</a-select-option>
                    <a-select-option value="S">Cônjuge</a-select-option>
                    <a-select-option value="R">Outro Parente</a-select-option>
                    <a-select-option value="F">Amigo</a-select-option>
                    <a-select-option value="O">Outro</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
      <a-col span="24">
        <a-form-item
          ref="travelWhoPayingPersonHasSameAddress"
          :required="vForm.travelWhoPaying == 'O'"
          label="O endereço do destinatário da viagem é o mesmo do seu endereço residencial ou de correspondência?"
          name="travelWhoPayingPersonHasSameAddress"
        >
          <a-radio-group
            v-model:value="vForm.travelWhoPayingPersonHasSameAddress"
            :readonly="readOnlyForm"
          >
            <a-radio-button value="Y">Sim</a-radio-button>
            <a-radio-button value="N">Não</a-radio-button>
          </a-radio-group>
        </a-form-item>
      </a-col>
      <div v-if="vForm.travelWhoPayingPersonHasSameAddress == 'N'">
        <a-row>
          <a-col span="24">
            <div :class="[$style.space, $style.addMore, $style.outSpace]">
              <a-row>
                <a-col span="24">
                  <CountrySelect
                    reference="travelWhoPayingPersonNoAddressCountry"
                    name="País"
                    :isRequired="vForm.travelWhoPayingPersonHasSameAddress == 'N'"
                  />
                  <a-col span="24" :class="$style.space">
                    <a-form-item
                      ref="travelWhoPayingPersonNoAddressZipCode"
                      label="CEP"
                      :required="selectedBrasil"
                      name="travelWhoPayingPersonNoAddressZipCode"
                    >
                      <a-input
                        @change="selectedBrasil && getWhoAddress()"
                        :disabled="isLoadingWhoPayingPerson"
                        :loading="isLoadingWhoPayingPerson"
                        v-maska="selectedBrasil ? '#####-###' : '#*'"
                        v-model:value="vForm.travelWhoPayingPersonNoAddressZipCode"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-row>
                    <a-col span="12" :class="$style.space">
                      <a-form-item
                        ref="travelWhoPayingPersonNoAddressLine1"
                        label="Endereço"
                        :required="vForm.travelWhoPayingPersonHasSameAddress == 'N'"
                        name="travelWhoPayingPersonNoAddressLine1"
                      >
                        <a-input
                          :disabled="isLoadingWhoPayingPerson"
                          v-model:value="vForm.travelWhoPayingPersonNoAddressLine1"
                          :readonly="readOnlyForm"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>
                    <a-col span="12" :class="$style.space">
                      <a-form-item
                        ref="travelWhoPayingPersonNoAddressLine2"
                        label="Complemento"
                        name="travelWhoPayingPersonNoAddressLine2"
                      >
                        <a-input
                          :disabled="isLoadingWhoPayingPerson"
                          v-model:value="vForm.travelWhoPayingPersonNoAddressLine2"
                          :readonly="readOnlyForm"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col span="12" :class="$style.space">
                      <a-form-item
                        ref="travelWhoPayingPersonNoAddressCity"
                        label="Cidade"
                        :required="vForm.travelWhoPayingPersonHasSameAddress == 'N'"
                        name="travelWhoPayingPersonNoAddressCity"
                      >
                        <a-input
                          :disabled="isLoadingWhoPayingPerson"
                          v-model:value="vForm.travelWhoPayingPersonNoAddressCity"
                          :readonly="readOnlyForm"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>
                    <a-col span="12" :class="$style.space">
                      <a-form-item
                        ref="travelWhoPayingPersonNoAddressState"
                        label="Estado"
                        :required="vForm.travelWhoPayingPersonHasSameAddress == 'N'"
                        name="travelWhoPayingPersonNoAddressState"
                      >
                        <a-input
                          :disabled="isLoadingWhoPayingPerson"
                          v-model:value="vForm.travelWhoPayingPersonNoAddressState"
                          :readonly="readOnlyForm"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>

    <div v-if="vForm.travelWhoPaying == 'C'">
      <a-row>
        <a-col span="24">
          <div :class="[$style.space, $style.addMore, $style.outSpace]">
            <a-row>
              <a-col :span="isDesktop ? 12 : 24" :class="$style.space">
                <a-form-item
                  ref="travelWhoPayingCompanyName"
                  label="Nome da empresa/organização que paga a viagem"
                  :required="vForm.travelWhoPaying == 'C'"
                  name="travelWhoPayingCompanyName"
                >
                  <a-input
                    v-model:value="vForm.travelWhoPayingCompanyName"
                    :readonly="readOnlyForm"
                  >
                    <template #suffix>
                      <a-tooltip :title="PASSPORT_WITHOUT_NAME">
                        <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
                      </a-tooltip>
                    </template>
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col :span="isDesktop ? 12 : 24" :class="$style.space">
                <a-form-item
                  ref="travelWhoPayingCompanyTelephone"
                  label="Telefone"
                  name="travelWhoPayingCompanyTelephone"
                  :required="vForm.travelWhoPaying == 'C'"
                >
                  <a-input
                    :readonly="readOnlyForm"
                    v-model:value="vForm.travelWhoPayingCompanyTelephone"
                  >
                    <template #suffix>
                      <a-tooltip :title="SURNAMES_LIKE_PASSPORT">
                        <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
                      </a-tooltip>
                    </template>
                  </a-input>
                </a-form-item>
              </a-col>
              <a-col span="24" :class="$style.space">
                <a-form-item
                  ref="travelWhoPayingCompanyRelation"
                  label="Relação com você"
                  :required="vForm.travelWhoPaying == 'C'"
                  name="travelWhoPayingCompanyRelation"
                >
                  <a-input
                    v-model:value="vForm.travelWhoPayingCompanyRelation"
                    :readonly="readOnlyForm"
                  >
                    <template #suffix>
                      <a-tooltip :title="PASSPORT_WITHOUT_NAME">
                        <info-circle-outlined style="color: rgba(0, 0, 0, 0.45)" />
                      </a-tooltip>
                    </template>
                  </a-input>
                </a-form-item>
              </a-col>
            </a-row>
          </div>
        </a-col>
      </a-row>
      <div>
        <a-row>
          <a-col span="24">
            <div :class="[$style.space, $style.addMore, $style.outSpace]">
              <a-row>
                <a-col span="24">
                  <CountrySelect
                    reference="travelWhoPayingCompanyAddressCountry"
                    name="País"
                    :isRequired="vForm.travelWhoPaying == 'C'"
                  />
                  <a-col span="24" :class="$style.space">
                    <a-form-item
                      ref="travelWhoPayingCompanyAddressZipCode"
                      label="CEP"
                      :required="selectedWhoPayingBrasil"
                      name="travelWhoPayingCompanyAddressZipCode"
                    >
                      <a-input
                        @change="selectedWhoPayingBrasil && getWhoCompanyAddress()"
                        :disabled="isLoadingWhoPayingCompany"
                        :loading="isLoadingWhoPayingCompany"
                        v-maska="selectedWhoPayingBrasil ? '#####-###' : '#*'"
                        v-model:value="vForm.travelWhoPayingCompanyAddressZipCode"
                        :readonly="readOnlyForm"
                      >
                      </a-input>
                    </a-form-item>
                  </a-col>
                  <a-row>
                    <a-col span="12" :class="$style.space">
                      <a-form-item
                        ref="travelWhoPayingCompanyAddressLine1"
                        label="Endereço"
                        :required="vForm.travelWhoPaying == 'C'"
                        name="travelWhoPayingCompanyAddressLine1"
                      >
                        <a-input
                          :disabled="isLoadingWhoPayingCompany"
                          v-model:value="vForm.travelWhoPayingCompanyAddressLine1"
                          :readonly="readOnlyForm"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>
                    <a-col span="12" :class="$style.space">
                      <a-form-item
                        ref="travelWhoPayingCompanyAddressLine2"
                        label="Complemento"
                        name="travelWhoPayingCompanyAddressLine2"
                      >
                        <a-input
                          :disabled="isLoadingWhoPayingCompany"
                          :readonly="readOnlyForm"
                          v-model:value="vForm.travelWhoPayingCompanyAddressLine2"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>
                  </a-row>
                  <a-row>
                    <a-col span="12" :class="$style.space">
                      <a-form-item
                        ref="travelWhoPayingCompanyAddressCity"
                        label="Cidade"
                        :required="vForm.travelWhoPaying == 'C'"
                        name="travelWhoPayingCompanyAddressCity"
                      >
                        <a-input
                          :disabled="isLoadingWhoPayingCompany"
                          v-model:value="vForm.travelWhoPayingCompanyAddressCity"
                          :readonly="readOnlyForm"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>
                    <a-col span="12" :class="$style.space">
                      <a-form-item
                        ref="travelWhoPayingCompanyAddressState"
                        label="Estado"
                        :required="vForm.travelWhoPaying == 'C'"
                        name="travelWhoPayingCompanyAddressState"
                      >
                        <a-input
                          :disabled="isLoadingWhoPayingCompany"
                          v-model:value="vForm.travelWhoPayingCompanyAddressState"
                          :readonly="readOnlyForm"
                        >
                        </a-input>
                      </a-form-item>
                    </a-col>
                  </a-row>
                </a-col>
              </a-row>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </a-col>
</template>
<script>
import { defineComponent } from "vue";
import { mapMutations, mapState, mapGetters } from "vuex";
import { PlusOutlined, MinusOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";
import { getAddress, getUFFullName } from "@/utils/getCep";
import CountrySelect from "@/components/CountrySelect/CountrySelect";
import StateUSSelect from "@/components/StateUSSelect/StateUSSelect";
import Suggestions from "@/components/Suggestions/Suggestions";
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_MAX_ADD_AMOUNT,
  AMOUNT_STAY,
} from "@/utils/Constants";
import { isDesktop } from "@/utils/isAgent";
import { sanitizeString } from "@/utils/utility";
import { getConsulCity } from "@/utils/utility";
import { validateDate } from "@/utils/validation";
import moment from "moment";

export default defineComponent({
  components: {
    PlusOutlined,
    CountrySelect,
    StateUSSelect,
    MinusOutlined,
    InfoCircleOutlined,
    Suggestions,
  },
  data() {
    return {
      amountTravel: 1,
      isLoadingCepIntent: false,
      isLoadingWhoPayingPerson: false,
      isLoadingWhoPayingCompany: false,
      DEFAULT_DATE_FORMAT,
      isDesktop,
      validateDate,
    };
  },
  computed: {
    ...mapState("application", ["vForm"]),
    ...mapGetters("application", ["readOnlyForm"]),
    ...mapGetters("system", ["travelSuggestions"]),
    amountLabel() {
      return `Quantidade ${
        this.vForm.travelNoIntentLengthStayType != "" &&
        this.vForm.travelNoIntentLengthStayType != "H"
          ? "de " + AMOUNT_STAY[this.vForm.travelNoIntentLengthStayType]
          : ""
      }`;
    },
    selectedBrasil() {
      return this.vForm.travelWhoPayingPersonNoAddressCountry == "BRZL";
    },
    selectedWhoPayingBrasil() {
      return this.vForm.travelWhoPayingCompanyAddressCountry == "BRZL";
    },
    canNotAddTravel() {
      return !(this.vForm.travelYesLocationsVisit.length < DEFAULT_MAX_ADD_AMOUNT);
    },
    canNotRemoveTravel() {
      return !(this.vForm.travelYesLocationsVisit.length > 1);
    },
    flightArrivalTooltip() {
      return `ex: estou partido de ${getConsulCity(
        this.vForm.consul
      )} com o voo da compania XXX com destido ao Estados Unidos`;
    },
    flightDepartureTooltip() {
      return `ex: estou partido dos Estados Unidos com o voo da compania XXX com destido a ${getConsulCity(
        this.vForm.consul
      )}`;
    },
    cityArrivalTooltip() {
      return `ex: estou partindo de ${getConsulCity(
        this.vForm.consul
      )} chegarei em XXX no Estados Unidos`;
    },
    cityDepartureTooltip() {
      return `ex: estou partindo de XXX no Estados Unidos chegarei a ${getConsulCity(
        this.vForm.consul
      )} no Brasil`;
    },
    formattedBirthDate() {
      return {
        day: parseInt(this.vForm.personal1BirthDay, 10),
        m: parseInt(this.vForm.personal1BirthMonth, 10),
        year: parseInt(this.vForm.personal1BirthYear, 10),
      };
    },
    hasLessThan14YearsOld() {
      const birthDate = this.formattedBirthDate;
      return moment().diff(birthDate, "years", false) < 14;
    },
    hasLessThan60YearsOldAndOver14() {
      const birthDate = this.formattedBirthDate;
      const isNewer =
        !this.hasLessThan14YearsOld && moment().diff(birthDate, "years", false) < 60;
      return isNewer;
    },
    hasOverThen60YearsOld() {
      const birthDate = this.formattedBirthDate;
      return moment().diff(birthDate, "years", false) > 60;
    },

    hasOverThan60YearsOldAndLessThan14YearsOld() {
      return this.hasLessThan14YearsOld || this.hasOverThen60YearsOld;
    },
  },
  methods: {
    ...mapMutations("application", ["setSaveFilledForm"]),
    overCurrentDate(current) {
      return current && current <= moment().endOf("day");
    },
    afterArrivedDate(current) {
      return current && current <= this.vForm.arrivalDate;
    },
    async getWhoAddress() {
      if (this.vForm.travelWhoPayingPersonNoAddressCountry != "BRZL") {
        return;
      }
      const cep = sanitizeString(this.vForm.travelWhoPayingPersonNoAddressZipCode);
      if (cep.length == 8) {
        this.isLoadingWhoPayingPerson = true;
        const address = await getAddress(cep);
        if (address) {
          this.vForm.travelWhoPayingPersonNoAddressLine1 = address?.logradouro;
          this.vForm.travelWhoPayingPersonNoAddressLine2 = address?.bairro;
          this.vForm.travelWhoPayingPersonNoAddressCity = address?.localidade;
          this.vForm.travelWhoPayingPersonNoAddressState = getUFFullName(address?.uf);
        }
        this.isLoadingWhoPayingPerson = false;
      }
    },
    async getWhoCompanyAddress() {
      if (this.vForm.travelWhoPayingCompanyAddressCountry != "BRZL") {
        return;
      }
      const cep = sanitizeString(this.vForm.travelWhoPayingPersonNoAddressZipCode);
      if (cep.length == 8) {
        this.isLoadingWhoPayingCompany = true;
        const address = await getAddress(cep);
        if (address) {
          this.vForm.travelWhoPayingCompanyAddressLine1 = address?.logradouro;
          this.vForm.travelWhoPayingCompanyAddressLine2 = address?.bairro;
          this.vForm.travelWhoPayingCompanyAddressCity = address?.localidade;
          this.vForm.travelWhoPayingCompanyAddressState = getUFFullName(address?.uf);
        }
        this.isLoadingWhoPayingCompany = false;
      }
    },
    addTravel() {
      if (!this.canNotAddTravel) {
        this.vForm.travelYesLocationsVisit.push({ value: "" });
      }
    },
    rmTravel() {
      if (this.vForm.travelYesLocationsVisit.length > 1) {
        this.vForm.travelYesLocationsVisit.pop();
      }
    },
    handleArrivalDate(val) {
      var check = moment(val, "YYYY/MM/DD");

      var month = check.format("MM");
      var day = check.format("DD");
      var year = check.format("YYYY");

      this.vForm.travelYesArrivalDay = day;
      this.vForm.travelYesArrivalMonth = month;
      this.vForm.travelYesArrivalYear = year;
    },
    handleDepartureDate(val) {
      var check = moment(val, "YYYY/MM/DD");

      var month = check.format("MM");
      var day = check.format("DD");
      var year = check.format("YYYY");

      this.vForm.travelYesDepartureDay = day;
      this.vForm.travelYesDepartureMonth = month;
      this.vForm.travelYesDepartureYear = year;
    },
    handleIntendedDate(val) {
      var check = moment(val, "YYYY/MM/DD");

      var month = check.format("MM");
      var day = check.format("DD");
      var year = check.format("YYYY");

      this.vForm.travelNoIntentArrivalDay = day;
      this.vForm.travelNoIntentArrivalMonth = month;
      this.vForm.travelNoIntentArrivalYear = year;
    },
  },
  watch: {
    hasLessThan60YearsOldAndOver14() {
      if (!this.hasLessThan60YearsOldAndOver14) {
        this.vForm.travelHasMadePlans = "N";
      }
    },
    hasLessThan14Years() {
      if (!this.hasLessThan14YearsOld) {
        this.vForm.travelHasMadePlans = "N";
      }
    },
  },
  mounted() {
    // this.vForm.arrivalDate = moment(this.vForm.arrivalDate);
    // this.vForm.departureDate = moment(this.vForm.departureDate);
    // this.vForm.intendedDate = moment(this.vForm.intendedDate);
  },
});
</script>

<style module src="./style.scss" lang="scss"></style>
